import { css } from '@emotion/css'
import Styles from '../../styles'

const { Colors } = Styles

export const header = css`
  height: 56px !important;
  box-shadow: 0 1px 0 #e2e8f0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const logo = css`
  margin-left: 32px;
  width: 120px;
  margin-top: -10px;
`

export const notifications = css`
  margin-right: 18px;
  display: flex !important;
  align-items: center !important;
`

export const userActions = css`
  margin-right: 32px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

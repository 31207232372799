import React from 'react'
import { Form, Input as AntInput } from 'antd'

import './overrides.css'

export type InputType = {
  name: string
  labelInline?: boolean
  label?: string
  value?: any
  errors?: any
  handleSubmit?: Function
  setFieldValue: Function
  setFieldTouched: Function
  handleBlur?: Function
  onChangeFunction?: Function
  type?: string
  short?: boolean
  disabled?: boolean
  bindValue?: any
  values: any
  step?: any
  min?: number
  max?: number
  placeholder?: string
  preventEnterBehaviour?: boolean
  autoFocus?: boolean
  addonAfter?: React.ReactNode
}

const Component = (props: InputType) => {
  const {
    name,
    labelInline,
    label,
    value,
    errors,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    handleBlur,
    onChangeFunction,
    type,
    short,
    disabled,
    bindValue,
    values,
    step,
    min,
    max,
    placeholder,
    preventEnterBehaviour,
    autoFocus,
    addonAfter,
  } = props

  const conditionalProps: any = {}
  if (bindValue) conditionalProps.value = values[name]

  const onChange = (event: any) => {
    if (!!onChangeFunction) {
      onChangeFunction(event.target.value)
    }
    props.setFieldValue(props.name, event.target.value)
  }

  return (
    <>
      <Form.Item
        hasFeedback={!!errors && !!errors[name]}
        validateStatus={!!errors && errors[name] && 'error'}
        help={!!errors && errors[name]}
        className="inputLabel"
        label={label}
        colon={false}
      >
        <AntInput
          autoFocus={autoFocus}
          name={props.name}
          style={props.short ? { width: '90px' } : undefined}
          className="input"
          placeholder={placeholder}
          addonAfter={props.addonAfter}
          type={props.type}
          disabled={props.disabled}
          value={values[name]}
          defaultValue={!!value ? value : null}
          onChange={(event) => onChange(event)}
          onBlur={() => props.setFieldTouched(props.name)}
          onPressEnter={() => (preventEnterBehaviour ? () => {} : props.handleSubmit ? props.handleSubmit() : () => {})}
          {...conditionalProps}
          step={step}
          min={min}
          max={max}
        />
      </Form.Item>
    </>
  )
}

export default Component

import { css } from '@emotion/css'
import Styles from '../../styles'

const { Colors } = Styles

export const textarea = css`
  border-radius: 6px !important;
  font-family: Inter, sans-serif !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: rgba(0, 0, 0, 0.85) !important;

  & :focus {
    border: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
  }
`

import { css } from '@emotion/css'

export const container = css`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  margin: auto;
  margin-top: 50px;
  max-width: 384px;
  max-height: 500px;
`
export const formBody = css`
  margin: 30px 0;
  width: 100%;
`

export const filterContainer = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  & .ant-row {
    margin-right: 16px;
    min-width: 300px;
    margin-bottom: 0;
  }
`

export const modalActions = css`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column-reverse;
  margin-top: 25px;
`

export const modalActionsRight = css`
  display: flex;
  max-width: 80px;
  display: flex;
  justify-content: flex-end !important;
  align-items: flex-end !important;
  flex-direction: column-reverse;
`

export const modalActionsRightContainer = css`
  display: flex;
  max-width: 250px;
  display: flex;
  justify-content: space-between;
  align-content: space-between;
  & button {
    margin-left: 8px !important;
  }
`

export const modalContent = css`
  margin: 15px 0;
  max-width: 400px;
`

export const clipboardDetail = css`
  display: flex;
  align-items: center;
`

export const clipboardIcon = css`
  display: flex;
  margin-top: 5px;
  margin-left: 10px;
`

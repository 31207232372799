import React from 'react'
import { Tag } from 'antd'

import * as Style from './style'

export type IProps = {
  status: 'default' | 'active' | 'inactive' | 'blocked' | 'alternative' | 'disabled' | 'success' | 'error'
  text?: string
}

export enum TagStyle {
  Default = 'blue',
  Active = 'green',
  Inactive = 'gold',
  Blocked = 'red',
  Alternative = 'cyan',
  Disabled = 'gray',
  Success = '#52c41a',
  Error = '#ff4d4f',
}

const TagComponent = ({ status, text }: IProps) => {
  let color = 'default'
  let defaultText = ''
  switch (status) {
    case 'default':
      color = TagStyle.Default
      defaultText = '-'
      break
    case 'active':
      color = TagStyle.Active
      defaultText = 'Active'
      break
    case 'inactive':
      color = TagStyle.Inactive
      defaultText = 'Inactive'
      break
    case 'alternative':
      color = TagStyle.Alternative
      defaultText = '-'
      break
  }
  return (
    <Tag className={Style.tag} color={color}>
      {text || defaultText}
    </Tag>
  )
}

export default TagComponent

export type TenantQueryResponse = {
  id: string
  companyName: string
  email: string
  status: 0 | 1
  createdAt: string
}

export type TenantApiKeysResponse = {
  apiKey: string
  secretKey: string
}

export type TenantSaveRequest = {
  tenantId: string
  firstName: string
  lastName: string
  companyName: string
  email: string
}

export enum Role {
  Attendee = 1,
  Moderator = 2,
  Presenter = 3,
}

export enum Permission {
  All = 0,
}

export const getRoleAsString = (role: Role) => {
  if (role === Role.Attendee) return 'Attendee'
  else if (role === Role.Moderator) return 'Moderator'
  else return 'Presenter'
}

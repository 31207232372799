import React from 'react'
import { ColorResult, SketchPicker } from 'react-color'
import { Form } from 'antd'

type Props = {
  name: string
  label?: string
  errors?: any
  setFieldValue: Function
  setFieldTouched: Function
  values: any
  onChange?: (hex: string) => {}
}

const Component = (props: Props) => {
  return (
    <Form.Item
      hasFeedback={!!props.errors && !!props.errors[props.name]}
      validateStatus={!!props.errors && props.errors[props.name] && 'error'}
      help={!!props.errors && props.errors[props.name]}
      className="inputLabel"
      label={props.label}
      colon={false}
    >
      <SketchPicker
        width={'250px'}
        color={props.values[props.name]}
        onChange={(color: ColorResult) => {
          props.setFieldValue(props.name, color.hex)
          if (!!props.onChange) {
            props.onChange(color.hex)
          }
        }}
      />
    </Form.Item>
  )
}

export default Component

import callApi from '../common/api/apiRequest'
import { WebhookEvent } from './models'

export type SaveWebhookRequest = {
  id?: string
  url: string
  description?: string
  events: WebhookEvent[]
}

export default async (values: SaveWebhookRequest): Promise<void> => {
  return callApi<void>('post', `webhooks`, values)
}

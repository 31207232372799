import { css } from '@emotion/css'
import Styles from '../../styles'

const { Colors } = Styles

export const title = css`
  font-family: Inter !important;
  font-size: 24px !important;
  line-height: 32px !important;
  align-items: center !important;
  font-weight: 600;
  color: #1a202c;
  margin: 0;
`

export const subtitle = css`
  font-family: Inter !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 22px !important;
  color: #808996; ;
`

export const smallTitle = css`
  font-family: Inter !important;
  font-weight: normal !important;
  font-size: 12px !important;
  line-height: 22px !important;
  color: #808996; ;
`

export const modalTitle = css`
  font-family: Inter !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: ${Colors.DarkGray} !important;
`

const BASE = 'rooms'

export const ROOMS_GET_URL = `${BASE}`
export const ROOMS_START_PRESENTATION_URL = `${BASE}/startPresentation`
export const ROOMS_SET_RECORDING_STARTED_TIME_URL = `${BASE}/SetRecordingStartedTime`
export const ROOMS_SET_LIVESTREAM_STARTED_TIME_URL = `${BASE}/SetLivestreamStartedTime`
export const ROOMS_END_PRESENTATION_URL = `${BASE}/endPresentation`
export const ROOMS_GET_ROOM_DETAILS_FOR_PARTICIPANT_URL = `${BASE}/detailsForParticipant`
export const ROOMS_JOIN_PRESENTATION_URL = `${BASE}/JoinPresentation`
export const ROOMS_GET_PUBLISHER_CREDENTIALS_URL = `${BASE}/PublisherCredentials`
export const ROOMS_GET_RECORDINGS_URL = `${BASE}/recordings`
export const ROOMS_GET_PARTICIPANTS_URL = `${BASE}/participants`
export const ROOMS_SAVE_PARTICIPANTS_URL = `${BASE}/participants`
export const ROOMS_GET_ROOM_PARTICIPANTS_FOR_SAVE_URL = `${BASE}/participants/forSave`
export const ROOMS_DELETE_PARTICIPANT = `${BASE}/participants`
export const ROOMS_GET_ROOM_DETAILS_BY_ID = `${BASE}/detailsById`
export const ROOMS_GET_ROOM_STATUS_URL = `${BASE}/status`
export const ROOMS_GET_FOR_SAVE_URL = `${BASE}/forSave`
export const ROOMS_GET_VERIFY_ATTENDEE_ROOM_DETAILS_URL = `${BASE}/verifyAttendeeRoomDetails`
export const ROOMS_ADD_TEMPORARY_ATTENDEE_TO_ROOM = `${BASE}/addTemporaryAttendeeToRoom`
export const ROOMS_REMOVE_TEMPORARY_ATTENDEE_TO_ROOM = `${BASE}/removeTemporaryAttendeeFromRoom`

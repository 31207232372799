import React, { useState } from 'react'
import { Tabs } from 'antd'

const { TabPane } = Tabs

import * as Style from './style'

export type IProps = {
  active: string
  tabs: {
    key: string
    title: React.ReactNode
    component: any
    hidden?: boolean
  }[]
  centered?: boolean
  headerBackground?: boolean
  onSelect: (activeKey: string) => void
  className?: string
}

const TabComponent = (props: IProps) => {
  const { active, onSelect, tabs, className, headerBackground, centered = false } = props
  const [activeTab, setActiveTab]: [string, Function] = useState(active)
  return (
    <Tabs
      className={`${headerBackground ? Style.tabsWithBackground : Style.tabs} ${className || ''}`}
      onChange={(key: string) => {
        setActiveTab(key)
        onSelect(key)
      }}
      activeKey={activeTab}
      centered={centered}
    >
      {tabs &&
        tabs.length &&
        tabs.map((tab) => {
          if (tab.hidden) return null
          return (
            <TabPane key={tab.key} tabKey={tab.key} tab={tab.title}>
              {tab.component}
            </TabPane>
          )
        })}
    </Tabs>
  )
}

export default TabComponent

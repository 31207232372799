import { PagedList } from './api'

export type SearchFilter = {
  q?: string
  pageNumber: number
  pageSize: number
  orderColumn: string
  orderDescending: boolean
}

export const EmptySearchFilter: SearchFilter = {
  pageNumber: 1,
  pageSize: 20,
  orderColumn: 'createdAt',
  orderDescending: true,
  q: '',
}

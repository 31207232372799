import React from 'react'
import Components from '@cloudmeet/web-components'

const { Title } = Components.UI

import * as Style from './style'

type IProps = {
  title?: string
  actions?: any
  children: React.ReactNode
}

const Component: React.FC<IProps> = (props: IProps) => {
  const { actions, children, title } = props
  return (
    <div className={Style.container}>
      <div className={Style.header}>
        {title ? <Title type="title" value={title} /> : null}
        <div className={Style.headerActions}>{actions}</div>
      </div>
      <div className={Style.body}>{children}</div>
    </div>
  )
}

export default Component

import React from 'react'
import * as Style from './style'

type IProps = {
  label: string
  value: string
  type: 'inline' | 'block'
}

const Component = ({ label, value, type }: IProps) => {
  let comp = null
  const kv = (
    <>
      <p className={Style.key}>{label}</p>
      <p className={Style.value}>{value}</p>
    </>
  )
  switch (type) {
    case 'inline':
      comp = <div className={Style.inline}>{kv}</div>
      break
    case 'block':
      comp = <div className={Style.block}>{kv}</div>
      break
  }
  return comp
}

export default Component

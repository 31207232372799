import { css } from '@emotion/css'
import Components from '@cloudmeet/web-components'

const { Colors } = Components.Styles

export const container = css`
  width: 100%;
  background-color: ${Colors.Gray2};
  overflow: auto;
`

export const header = css`
  display: flex;
  justify-content: space-between;
  padding: 24px 32px;
  margin-bottom: 15px;
`

export const headerTitle = css``

export const headerActions = css``

export const body = css`
  padding: 0 32px;
`

import React from 'react'
import { useParams } from 'react-router-dom'
import useQueryRequestV2 from '../common/hooks/useQueryRequestV2'
import { getRoomUsage, RoomUsageResponse } from '@cloudmeet/web-core/rooms/getRoomUsage'
import Components from '@cloudmeet/web-components'
import PageCard from '../common/components/PageCard'
import { RoomType } from '@cloudmeet/web-core/rooms/models'

const { Skeleton, Card, Statistic, Row, Col } = Components.UI

export default () => {
  const params: any = useParams()
  const roomId = params.id ?? ''

  const [roomUsage] = useQueryRequestV2(null, () => getRoomUsage(roomId))

  return (
    <Skeleton loading={!roomUsage}>
      {roomUsage && (
        <PageCard title={`Room Usage for '${roomUsage.name}'`}>
          {(roomUsage.roomType === RoomType.Booth || roomUsage.roomType === RoomType.RoundTable) && (
            <BoothRoundTableStatistic roomUsage={roomUsage} />
          )}

          {(roomUsage.roomType === RoomType.Keynote || roomUsage.roomType === RoomType.PanelGroup) && (
            <KeynotePanelGroupStatistic roomUsage={roomUsage} />
          )}
        </PageCard>
      )}
    </Skeleton>
  )
}

const BoothRoundTableStatistic = (props: { roomUsage: RoomUsageResponse }) => {
  return (
    <Row gutter={16}>
      <Col span={6}>
        <Card>
          <Statistic
            title={`Total participants joined`}
            value={`${
              props.roomUsage.totalVideoConferenceParticipants
            } ($${props.roomUsage.videoConferenceParticipantsCost.toFixed(2)})`}
          />
        </Card>
      </Col>
      <Col span={6}>
        <Card>
          <Statistic
            title={`Recording minutes`}
            value={`${
              props.roomUsage.totalVideoConferenceRecordingMinutes
            } min ($${props.roomUsage.videoConferenceRecordingCost.toFixed(2)})`}
          />
        </Card>
      </Col>
      <Col span={6}>
        <Card>
          <Statistic
            title={`Total participants on realtime store`}
            value={`${
              props.roomUsage.totalParticipantsJoinedOnRealtimeStore
            } ($${props.roomUsage.totalParticipantsJoinedOnRealtimeStoreCost.toFixed(2)})`}
          />
        </Card>
      </Col>
    </Row>
  )
}

const KeynotePanelGroupStatistic = (props: { roomUsage: RoomUsageResponse }) => {
  return (
    <Row gutter={16}>
      <Col span={6}>
        <Card>
          <Statistic
            title={`Total panelists`}
            value={`${
              props.roomUsage.totalVideoConferenceParticipants
            } ($${props.roomUsage.videoConferenceParticipantsCost.toFixed(2)})`}
          />
        </Card>
      </Col>
      <Col span={6}>
        <Card>
          <Statistic
            title={`Livestream cost`}
            value={`${formatGbInMb(props.roomUsage.broadcastLivestreamGb)} MB - ${
              props.roomUsage.totalVideoConferenceLivestreamMinutes
            } minutes ($${props.roomUsage.totalLivestreamCost.toFixed(3)})`}
          />
        </Card>
      </Col>
      <Col span={6}>
        <Card>
          <Statistic
            title={`Recording cost`}
            value={`${formatGbInMb(
              props.roomUsage.broadcastRecordingGb,
            )}MB ($${props.roomUsage.broadcastRecordingCost.toFixed(3)})`}
          />
        </Card>
      </Col>
      <Col span={6}>
        <Card>
          <Statistic
            title={`Total participants on realtime store`}
            value={`${
              props.roomUsage.totalParticipantsJoinedOnRealtimeStore
            } ($${props.roomUsage.totalParticipantsJoinedOnRealtimeStoreCost.toFixed(2)})`}
          />
        </Card>
      </Col>
    </Row>
  )
}

const formatGbInMb = (gigabytes: number): string => {
  return (gigabytes * 1024).toFixed(2)
}

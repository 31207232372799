import callApi from '../common/api/apiRequest'
import { WebhookEvent } from './models'
import { PagedList } from '../common/models/api'
import { SearchFilter } from '../common/models/searchFilter'

export type WebhookResponse = {
  id: string
  url: string
  events: WebhookEvent[]
  createdAt: Date
}

export default async (request: SearchFilter): Promise<PagedList<WebhookResponse>> => {
  return callApi<PagedList<WebhookResponse>>('get', `webhooks`, request)
}

import { SearchFilter } from '../common/models/searchFilter'

export enum RoomType {
  Keynote = 0,
  Booth = 1,
  PanelGroup = 2,
  RoundTable = 3,
}

export type RoomFilter = {
  type?: RoomType
} & SearchFilter

export enum RoomRecordingOption {
  NoRecording = 0,
  RecordingIsRequired = 1,
  RecordingIsDefinedByHost = 2,
}

export type StreamerMediaServerAuthRequest = {
  streamName: string
  jwt: string
}

export type StreamerMediaServerAuthResponse = {
  wss: string
  jwt: string
}

export type BroadcastRequest = {
  websocketUrl: string
  jwt: string
  stream: MediaStream
  streamName: string
}

export type CaptureBroadcastRequest = {
  // websocketUrl: string
  // jwt: string
  streamName: string
  accountId: string
  subscriberToken: string
}

export type SteamerMediaServerData = {
  name: string
  sdp: RTCSessionDescription
  codec: string
}

export type StreamerMediaServerPayload = {
  type: string
  transId: Number
  name: string
  data: SteamerMediaServerData
}

export type StreamerAccountSettings = {
  streamName: string
  accountId: string
  token: string
}

// Old format for ICE Server in Streamer
export type StreamerOldICEServers = {
  url: string[]
}

export type StreamerICEServers = {
  urls: string[]
}

export type ParticipantDto = {
  id: string
  name: string
  roomId: string
  conferenceId: string
  role: number
  isHost: boolean
  participantKey: string
  roleCustomLabel: string
}

export enum RoomParticipantPermission {
  StartPresentation,
  EndPresentation,
  CreateOneBreakoutRoom,
  StartBreakoutRoomsSession,
  CloseBreakoutRooms,
  CloseOneBreakoutRoom,
  KickOutParticipant,
  StartLivestream,
  StartRecording,
  ChangeHost,
  AddNote,
  ManageQuestions,
  GetRoomPublisherCredentials,
  GetBreakoutRoomsWithParticipants,
}

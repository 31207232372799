import { Permission } from '../tenants/models'
import callApi from '../common/api/apiRequest'

export type LoginUserDetailsResponse = {
  firstName: string
  lastName: string
  email: string
  permissions: Permission[]
}
export const getLoginUserDetails = async () => {
  return callApi<LoginUserDetailsResponse>('get', `me`)
}

import React, { useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'

import * as Routes from '../appContainer/routes'
import { parseHashArgs } from '@cloudmeet/web-core/common/utils/urlUtils'

const Login: React.FC = () => {
  const history = useHistory()
  const location = useLocation()
  const params = parseHashArgs(location.hash.toString())

  useEffect(() => {
    localStorage.setItem('token', params.id_token)
    history.replace(Routes.DASHBOARD)
  }, [])

  return null
}

export default Login

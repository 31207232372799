import { useState } from 'react'

import Components from '@cloudmeet/web-components'
import { showErrors } from '@cloudmeet/web-components/src/utils/notifications'
const { error } = Components.Utils.Notifications
import CognitoConfig, { getCognitoUrl } from '@cloudmeet/web-core/common/config/cognito'

type CommandResponse<T> = {
  errors: string[]
  data: T | null
  hasErrors: boolean
  completed: boolean
}

const ok = <T>(data: any): CommandResponse<T> => {
  return {
    errors: [],
    hasErrors: false,
    data,
    completed: true,
  }
}

const badRequest = <T>(errors: []): CommandResponse<T> => {
  return {
    errors,
    hasErrors: true,
    data: null,
    completed: false,
  }
}

export type SendCommandRequestOptions = {
  showErrors: boolean
}

export default <T>(): [
  (func: () => Promise<T>, opts?: SendCommandRequestOptions) => Promise<CommandResponse<T>>,
  boolean,
] => {
  const [isLoading, setIsLoading] = useState(false)

  const sendCommandRequest = async (
    func: () => Promise<T>,
    opts?: SendCommandRequestOptions,
  ): Promise<CommandResponse<T>> => {
    try {
      setIsLoading(true)
      const result = await func()
      setIsLoading(false)
      return ok(result)
    } catch (err) {
      setIsLoading(false)
      const displayErrors = !opts || opts.showErrors
      if (err.response && err.response.status === 401) {
        ;(window.location as any) = getCognitoUrl(CognitoConfig)
        return badRequest([])
      }

      if (err.response.status === 400) {
        let errors = []
        try {
          errors = err.response.data.map((x: any) => x.message)
        } catch (e) {}

        if (displayErrors) {
          showErrors(errors)
        }
        return badRequest(errors)
      }

      if (displayErrors) {
        await error('An error occurred while processing your request')
      }

      return badRequest([])
    }
  }

  return [sendCommandRequest, isLoading]
}

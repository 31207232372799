import React from 'react'
import { Form, Switch } from 'antd'

import * as Style from './style'

const SwitchInput = ({
  values,
  errors,
  handleSubmit,
  setFieldValue,
  setFieldTouched,
  name,
  labelInline,
  label,
  touched,
  defaultChecked,
  onChange,
}: {
  name?: any
  labelInline?: boolean
  label?: any
  values?: any
  errors?: any
  handleSubmit: Function
  setFieldValue: Function
  setFieldTouched: Function
  type?: string
  touched?: any
  defaultChecked?: boolean
  onChange?: Function
}) => (
  <Form.Item
    hasFeedback={!!(errors[name] && touched[name])}
    validateStatus={errors[name] && touched[name] && 'error'}
    help={errors[name] && touched[name] ? errors[name] : null}
    label={label}
    className={`${labelInline ? Style.inlineLabel : ''} ${Style.switchContainer}`}
  >
    <Switch
      defaultChecked={defaultChecked}
      checked={values[name]}
      onChange={(value) => {
        setFieldValue(name, value)
        if (onChange) {
          onChange()
        }
      }}
    />
  </Form.Item>
)

export default SwitchInput

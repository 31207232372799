import { css } from '@emotion/css'
import Styles from '../../styles'

const { Colors } = Styles

const buttonStyle = css`
  max-width: 384px;
  background: ${Colors.PrimaryColor} !important;
  border-radius: 6px !important;
  text-align: center !important;
  width: 100% !important;
  margin: auto !important;
  outline: none !important;
  color: white !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  font-family: Inter !important;
  font-weight: 500;
  border: none !important;

  &:focus,
  &:active {
    border: none !important;
  }

  &:disabled {
    background-color: #989898 !important;
  }
`

export const normal = css(
  buttonStyle,
  css`
    height: 40px !important;
  `,
)

export const success = css(
  buttonStyle,
  css`
    border: 1px solid ${Colors.Green} !important;
    box-sizing: border-box !important;
    border-radius: 5px !important;
    background: ${Colors.Green} !important;
    color: #fff !important;
    height: 40px !important;
  `,
)

export const secondary = css(
  buttonStyle,
  css`
    border: 1px solid ${Colors.LightGray} !important;
    box-sizing: border-box !important;
    border-radius: 5px !important;
    background: ${Colors.White} !important;
    color: ${Colors.DarkGray} !important;
    height: 40px !important;
  `,
)

export const warning = css(
  buttonStyle,
  css`
    height: 40px !important;
    background-color: ${Colors.SunsetOrange} !important;
  `,
)
//dangerDark
export const dangerDark = css(
  buttonStyle,
  css`
    height: 40px !important;
    background-color: #1a202c !important;
    color: ${Colors.Red} !important;
  `,
)

export const danger = css(
  buttonStyle,
  css`
    height: 40px !important;
    background-color: ${Colors.Red} !important;
  `,
)

export const transparent = css(
  buttonStyle,
  css`
    width: 120px !important;
    background-color: transparent !important;
    color: white;
    border: 1px solid white !important;
  `,
)

export const link = css`
  font-size: 12px !important;
  color: ${Colors.PrimaryColor} !important;
`

export const light = css`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${Colors.Gray2};
  border: none;
  &:hover {
    background: inherit !important;
  }
`

export const spinWrapper = css`
  margin-top: 6px;
`

export const spin = css`
  .ant-spin-dot-item {
    background-color: white;
  }
`

import { DependencyList, EffectCallback } from 'react'
import { ConfigOptions } from 'antd/lib/message'
import { setupNotifications } from '../utils/notifications'
import useDidMountEffect from './useDidMountEffect'

const useNotification = (config?: ConfigOptions) => (effect: EffectCallback, deps?: DependencyList) => {
  useDidMountEffect(() => {
    setupNotifications(config)
  }, [])
}

export default useNotification

import React, { useEffect, useState } from 'react'
import { Formik, FormikValues } from 'formik'
import { useHistory, useParams } from 'react-router-dom'
import Components from '@cloudmeet/web-components'

import * as Style from './style'
import useQueryRequest from '../common/hooks/useQueryRequest'
import useCommandRequest from '../common/hooks/useCommandRequest'
import * as Routes from '../appContainer/routes'
import { getTenantsForSave, GetTenantsForSaveResponse, saveTenant } from '@cloudmeet/web-core/tenants/api'
import * as Yup from 'yup'

/** ------------------------------------ **
 Global declarations
 ** ------------------------------------ **/
const { Button, Title, Input, ColorPicker } = Components.UI

export default () => {
  /** ------------------------------------ **
   Hooks declaration
   ** ------------------------------------ **/
  const history = useHistory()
  const params: any = useParams()

  const entityId = params.id ?? null
  const isEdit = Boolean(entityId)

  /** ------------------------------------ **
   Local States
   ** ------------------------------------ **/

  const [initialValues, setInitialValues] = useState<GetTenantsForSaveResponse | null>(null)
  const { sendQueryRequest } = useQueryRequest()
  const { isLoading: isSaving, sendCommandRequest } = useCommandRequest()

  /** ------------------------------------ **
   Effects
   ** ------------------------------------ **/
  useEffect(() => {
    ;(async () => {
      const result = await sendQueryRequest<GetTenantsForSaveResponse>(() => getTenantsForSave(entityId ?? ''))
      setInitialValues(result)
    })()
  }, [])

  /** ------------------------------------ **
   Event Handlers
   ** ------------------------------------ **/
  const onChangesSave = async (values: any) => {
    const result = await sendCommandRequest(() =>
      saveTenant({
        ...values,
        tenantId: entityId,
        backgroundColor: '#1A202C',
      }),
    )

    if (!result.hasErrors) {
      history.push(Routes.TENANTS)
    }
  }

  /** ------------------------------------ **
   Main Component
   ** ------------------------------------ **/
  return (
    initialValues && (
      <div className={Style.container}>
        <div className={Style.formTitle}>
          <Title value={isEdit ? 'Edit client' : 'Add new client'} type="title" />
          <Title value="Enter client details" type="small-title" />
        </div>
        <div>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={TenantEditValidationSchema}
            onSubmit={(values: FormikValues) => onChangesSave(values)}
          >
            {(form) => {
              return (
                <>
                  <Input {...form} name={'companyName'} label={'Company Name'} />
                  <div className={Style.hGroup}>
                    <Input {...form} name={'firstName'} label={'First Name'} />
                    <Input {...form} name={'lastName'} label={'Last Name'} />
                  </div>
                  <Input {...form} name={'email'} label={'Email'} />
                  {!isEdit && <Input type={'password'} {...form} name={'password'} label={'Password'} />}
                  <ColorPicker {...form} name={'primaryColor'} label={'Primary Color'} />
                  <Button
                    loading={isSaving}
                    disabled={isSaving}
                    label={isEdit ? 'Save Changes' : 'Add Client'}
                    type="normal"
                    onClick={() => form.handleSubmit()}
                  />
                </>
              )
            }}
          </Formik>
        </div>
      </div>
    )
  )
}

export const TenantEditValidationSchema = Yup.object().shape({
  firstName: Yup.string().required('First Name is required').nullable(),
  lastName: Yup.string().required('Last Name is required').nullable(),
  email: Yup.string().email('Email format is invalid').required('Email is required').nullable(),
  companyName: Yup.string().required('Company Name is required').nullable(),
  primaryColor: Yup.string()
    .required('Primary Ccolor is required')
    .nullable()
    .matches(/^#[a-fA-F0-9]{6}$/, 'Invalid color'),
  backgroundColor: Yup.string()
    .required('Background color is required')
    .nullable()
    .matches(/^#[a-fA-F0-9]{6}$/, 'Invalid color'),
})

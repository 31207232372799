import callApi from '../common/api/apiRequest'

export type WebhookSecretKeyResponse = {
  signingSecret: string
  url: string
}

export default async (id: string): Promise<WebhookSecretKeyResponse> => {
  return callApi<WebhookSecretKeyResponse>('get', `webhooks/secretKey/${id}`, {})
}

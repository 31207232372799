import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Components from '@cloudmeet/web-components'
import useQueryRequestV2 from '../common/hooks/useQueryRequestV2'
import { EmptyPagedList } from '@cloudmeet/web-core/common/models/api'
import { replaceId } from '@cloudmeet/web-core/common/utils/urlUtils'
import * as Routes from '../appContainer/routes'
import { toFullDateFromString } from '@cloudmeet/web-core/common/utils/dateUtils'
import PageCard from '../common/components/PageCard'
import { EmptySearchFilter, SearchFilter } from '@cloudmeet/web-core/common/models/searchFilter'
import getWebhooks from '@cloudmeet/web-core/webhooks/getWebhooks'
import { NamedItem } from '@cloudmeet/web-core/common/models/namedItem'
import useCommandRequestV2 from '../common/hooks/useCommandRequestV2'
import deleteWebhook from '@cloudmeet/web-core/webhooks/deleteWebhook'
import getWebhookSecretKey, { WebhookSecretKeyResponse } from '@cloudmeet/web-core/webhooks/getWebhookSecretKey'
import * as Style from './style'
import CopyToClipboard from 'react-copy-to-clipboard'

const { Button, Table, TableActions, Input, DeleteModal, Tag, Modal, Title, Detail, Icon } = Components.UI
const { Colors } = Components.Styles

export default () => {
  /** ------------------------------------ **
     Hooks declaration
     ** ------------------------------------ **/
  const history = useHistory()

  /** ------------------------------------ **
     Local states
     ** ------------------------------------ **/
  const [filter, setFilter] = useState<SearchFilter>(EmptySearchFilter)
  const [itemToDelete, setItemToDelete] = useState<any>(null)
  const [pageList, isPageListLoading, sendGetRequest] = useQueryRequestV2(EmptyPagedList())
  const [, isGetWebhookSecretKey, sendGetWebhookSecretKey] = useQueryRequestV2<WebhookSecretKeyResponse>()
  const [sendDeleteWebhook, isDeleteWebhookLoading] = useCommandRequestV2()
  const [webhookSecretKey, setWebhookSecretKey] = useState<WebhookSecretKeyResponse | null>(null)

  const columns: any[] = [
    {
      title: 'Url',
      dataIndex: 'url',
      key: 'url',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Events',
      dataIndex: 'events',
      key: 'events',
      sorter: false,
      render: (_: any, record: any) => record.eventsAsNamedItems.map((x: NamedItem) => x.name).join(', '),
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: (_: any, record: any) => toFullDateFromString(record.createdAt),
    },
    {
      width: '200px',
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      render: (_: any, record: any) => (
        <TableActions
          actions={{
            Actions: [
              {
                icon: 'action-edit-gray.svg',
                title: 'Edit Webhook',
                onClick: () => {
                  history.push(replaceId(Routes.WEBHOOKS_EDIT, record.id))
                },
              },
              {
                icon: 'action-remove-orange.svg',
                color: Colors.SunsetOrange,
                title: 'Delete Webhook',
                onClick: () => {
                  setItemToDelete(record)
                },
              },
            ],
            Keys: [
              {
                icon: 'action-lock-gray.svg',
                title: 'Show Keys',
                onClick: () => onShowKeysClick(record.id),
              },
            ],
          }}
        />
      ),
    },
  ]

  const columnToSort: any = columns.find((c) => c.dataIndex === filter.orderColumn)
  columnToSort.sortOrder = filter.orderDescending ? 'descend' : 'ascend'

  const refreshWebhooks = async () => {
    await sendGetRequest(() => getWebhooks(filter))
  }

  /** ------------------------------------ **
     Effects
     ** ------------------------------------ **/

  useEffect(() => {
    ;(async () => {
      await refreshWebhooks()
    })()
  }, [filter])

  /** ------------------------------------ **
     Event Handlers
     ** ------------------------------------ **/
  const onTableChange = (pagination: any, filters: any, sorter: any) => {
    setFilter({
      ...filter,
      pageNumber: pagination.current,
      pageSize: pagination.pageSize,
      orderColumn: sorter.field || 'createdAt',
      orderDescending: sorter.order !== 'ascend',
    })
  }

  const onDelete = async (webhookId: string) => {
    const result = await sendDeleteWebhook(() => deleteWebhook(webhookId))
    if (result.completed) {
      setItemToDelete(null)
      await refreshWebhooks()
    }
  }

  const onShowKeysClick = async (webhookId: string) => {
    const result = await sendGetWebhookSecretKey(() => getWebhookSecretKey(webhookId))
    setWebhookSecretKey(result)
  }

  const actions = [
    <div>
      <Button
        label={`Add webhook`}
        type="normal"
        icon={<i className="ri-add-line mr-4"></i>}
        onClick={() => {
          history.push(Routes.WEBHOOKS_ADD)
        }}
      />
    </div>,
  ]

  /** ------------------------------------ **
     Main Component
     ** ------------------------------------ **/
  return (
    <>
      <PageCard title={'Webhooks'} actions={actions}>
        <Table
          loading={isPageListLoading}
          pagination={{
            total: pageList?.totalItemCount,
            pageSize: pageList?.pageSize,
            current: pageList?.pageNumber,
            showSizeChanger: true,
            pageSizeOptions: ['5', '10', '20', '30', '40', '50'],
          }}
          dataSource={pageList?.items}
          columns={columns}
          onChange={onTableChange}
        />

        <WebhookSecretKeyModal
          visible={webhookSecretKey != null}
          webhookSecretKey={webhookSecretKey}
          onClosed={() => {
            setWebhookSecretKey(null)
          }}
        />

        <DeleteModal
          visible={itemToDelete != null}
          name={itemToDelete?.url}
          onCancelClick={() => {
            setItemToDelete(null)
          }}
          onDeleteClick={() => onDelete(itemToDelete.id)}
          deleteButtonLoading={isDeleteWebhookLoading}
        />
      </PageCard>
    </>
  )
}

type WebhookSecretKeyModalProps = {
  visible: boolean
  webhookSecretKey?: WebhookSecretKeyResponse | null
  onClosed: () => void
}
const WebhookSecretKeyModal = ({ webhookSecretKey, visible, onClosed }: WebhookSecretKeyModalProps) => (
  <Modal visible={visible}>
    <Title type="modal-header" value={`Url: ${webhookSecretKey?.url}`} />
    <div className={Style.modalContent}>
      <div className={Style.clipboardDetail}>
        <Detail label="Signing Secret" value={webhookSecretKey?.signingSecret ?? ''} type={'block'} />
        <div className={Style.clipboardIcon}>
          <CopyToClipboard text={webhookSecretKey?.signingSecret ?? ''}>
            <Icon iconName="copy-to-clipboard.svg" />
          </CopyToClipboard>
        </div>
      </div>
    </div>
    <div className={Style.modalActionsRight}>
      <Button label="Close" type="secondary" onClick={() => onClosed()} />
    </div>
  </Modal>
)

/**
 * App Routes
 */
export const DASHBOARD = '/'
export const TENANTS = '/clients'
export const TENANT_EDIT = '/clients/edit/:id'
export const TENANT_ADD = '/clients/add'

/**
 * Meetings
 */
export const KEYNOTES = '/keynotes'
export const ROUND_TABLE = '/round-table'
export const PANEL_GROUP = '/panel-group'
export const BOOTH = '/booth'
export const ROOMS_MANAGE = '/rooms/manage/:id'
export const ROOMS_ADD = '/rooms/manage'
export const PARTICIPANTS = '/participants/:id'
export const PARTICIPANTS_MANAGE = '/participants/:id/manage'
export const ROOM_USAGE = '/participants/:id/usage'

/**
 * Webhooks
 */
export const WEBHOOKS = '/webhooks'
export const WEBHOOKS_EDIT = '/webhooks/manage/:id'
export const WEBHOOKS_ADD = '/webhooks/manage'
/**
 * Auth
 */
export const LOGIN = '/login'

/**
 * Misc Routes
 */
export const NOT_FOUND = '/not-found'

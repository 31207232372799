import { css } from '@emotion/css'
import Styles from '../../styles'

const { Colors } = Styles

export const select = css`
  & .ant-select-selector {
    border-radius: 5px !important;
    font-family: Inter !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 14px !important;
    line-height: 22px !important;
    display: flex !important;
    align-items: center !important;
    color: ${Colors.Gray8};
    min-width: 150px;
  }
`

import React, { MouseEventHandler } from 'react'
import { Spin, Button } from 'antd'
import * as Style from './style'

export type ButtonCompType = {
  label: any
  type: 'normal' | 'secondary' | 'link' | 'warning' | 'light' | 'danger' | 'dangerDark' | 'success' | 'transparent'
  onClick: MouseEventHandler<HTMLElement>
  htmlType?: 'button' | 'submit' | 'reset' | undefined
  icon?: any
  disabled?: boolean
  className?: string
  loading?: boolean
  isCircle?: boolean
}

const Component = ({
  label,
  type,
  htmlType,
  onClick,
  disabled,
  icon,
  className,
  loading,
  isCircle,
}: ButtonCompType) => {
  switch (type) {
    case 'normal':
      className = Style.normal + ' ' + className
      break
    case 'secondary':
      className = Style.secondary + ' ' + className
      break
    case 'link':
      className = Style.link + ' ' + className
      break
    case 'warning':
      className = Style.warning + ' ' + className
      break
    case 'light':
      className = Style.light + ' ' + className
      break
    case 'danger':
      className = Style.danger + ' ' + className
      break
    case 'dangerDark':
      className = Style.dangerDark + ' ' + className
      break
    case 'success':
      className = Style.success + ' ' + className
      break
    case 'transparent':
      className = Style.transparent + ' ' + className
      break
  }
  return (
    <Button
      className={className}
      value={label}
      icon={icon}
      onClick={onClick}
      type={type === 'link' ? type : 'default'}
      htmlType={htmlType}
      disabled={disabled}
      shape={isCircle ? 'circle' : undefined}
    >
      {loading ? (
        <div className={Style.spin}>
          <Spin size="default" />
          <span style={{ marginLeft: 15 }}>{label}</span>
        </div>
      ) : (
        label
      )}
    </Button>
  )
}

export default Component

import Button from './Button'
import Input from './Input'
import Textarea from './Textarea'
import Select from './Select'
import Switch from './Switch'
import Title from './Title'
import Detail from './Detail'
import Icon from './Icon'
import Modal from './Modal'
import Tag from './Tag'
import Tab from './Tab'
import Header from './Header'
import Table from './Table'
import TableActions from './Table/TableActions'
import SideMenu from './Navigation/SideMenu'
import LoggedInUserHeader from './User/LoggedInUserHeader'
import UserAvatar from './User/UserAvatar'
import DeleteModal from './DeleteModal'
import PopConfirm, { PopConfirmButtonStyles } from './Popconfirm'
import ColorPicker from './ColorPicker'
import Popover from './Popover'
import Tooltip from './Tooltip'
import Avatar from './Avatar'
import Multiselect from './Multiselect'
import Timer from './Timer'
import Statistic from './Statistic'
import Card from './Card'
import Skeleton from './Skeleton'
import Row from './Grid/row'
import Col from './Grid/col'

export default {
  Button,
  Input,
  Textarea,
  Select,
  Switch,
  Title,
  Detail,
  Icon,
  Modal,
  Tag,
  Tab,
  Table,
  TableActions,
  Header,
  Navigation: {
    SideMenu,
  },
  User: {
    LoggedInUserHeader,
    UserAvatar,
  },
  DeleteModal,
  PopConfirm,
  PopConfirmButtonStyles,
  ColorPicker,
  Popover,
  Tooltip,
  Avatar,
  Timer,
  Multiselect,
  Statistic,
  Card,
  Skeleton,
  Row,
  Col,
}

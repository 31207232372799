import { css } from '@emotion/css'
import Styles from '../../styles'

const { Colors } = Styles

export const select = css`
  & .ant-select-selector {
    border-radius: 5px !important;
    font-family: Inter !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 14px !important;
    line-height: 22px !important;
    display: flex !important;
    align-items: center !important;
    color: ${Colors.Gray8};
    min-width: 150px;
    height: 32px;
  }
`
export const inlineLabel = css`
  color: red;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  & .ant-form-item-label {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: ${Colors.Gray9}
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    margin: 2px 0px;
  }
`

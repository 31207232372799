export type ApiQueryMultiple = {
  items: any[]
  totalItemCount: number
  pageCount: number
  pageSize: number
  pageNumber: number
}

export type PagedList<T> = {
  items: T[]
  totalItemCount: number
  pageCount: number
  pageSize: number
  pageNumber: number
}

export const EmptyPagedList = <T>(): PagedList<T> => {
  return {
    items: [],
    pageCount: 0,
    pageNumber: 1,
    pageSize: 25,
    totalItemCount: 0,
  }
}

import React from 'react'
import { Avatar } from 'antd'

export type AvatarCompType = {
  innerText: string
  size?: number | 'default' | 'large' | 'small' | any | undefined
  style?: any
  icon?: any
  src?: string
  isSquare?: boolean
  draggable?: boolean
}

const AVATAR_DEFAULT_INITIALS = 'CM'

const Component = ({ innerText, style, size, icon, src, isSquare, draggable }: AvatarCompType) => {
  const avatarTextInitials = (text: string) => {
    let result
    const parsed = parseInt(text, 10)
    if (!isNaN(parsed)) {
      return `+${parsed}`
    }
    const textArr = text.split(' ')
    if (textArr.length >= 2) {
      result = textArr[0].substr(0, 1) + textArr[1].substr(0, 1)
      return result.toUpperCase()
    }
    result = textArr[0].substr(0, 1)
    if (!result) return AVATAR_DEFAULT_INITIALS
    return result.toUpperCase()
  }
  return (
    <Avatar
      size={size}
      icon={icon}
      style={style}
      src={src}
      shape={isSquare ? 'square' : 'circle'}
      draggable={draggable}
    >
      {avatarTextInitials(innerText)}
    </Avatar>
  )
}

export default Component

import { css } from '@emotion/css'
import Styles from '../../../styles'

const { Colors } = Styles

export const container = css`
  display: flex;
  flex-direction: column;
  width: 220px;
  box-shadow: inset -1px 0px 0px #edf2f7;
  color: ${Colors.Gray8};
  background: white;
  height: calc(100vh - 56px);
`

export const menuItem = css`
  display: flex;
  height: 40px;
  max-width: 184px;
  border-radius: 6px;
  padding: 10px 10px 10px 25px;
  cursor: pointer;
  align-self: center;
  width: 180px;
  &:hover {
    background: ${Colors.Gray2};
  }
  & i {
    margin-right: 8px;
  }
`

export const menuItemActive = css`
  ${menuItem};
  background: ${Colors.DaybreakBlueLight};
  color: ${Colors.PrimaryColor};
  &:hover {
    background: ${Colors.DaybreakBlueLight};
    color: ${Colors.PrimaryColor};
  }
`

export const menuItemText = css`
  font-family: Inter, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  margin-top: -2px;
  display: flex;
`

export const menuItemTextActive = css`
  ${menuItemText};
  color: ${Colors.PrimaryColor};
`

export const menuItemIcon = css`
  margin-right: 8px;
`

export const menuHeader = css`
  margin-bottom: 10px;
  margin-left: 16px;
  margin-top: 10px;
  font-family: Inter, serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: ${Colors.Gray6};
`

export const parseHashArgs = (url: string) => {
  const vars: any = {}
  const hashes = url.slice(url.indexOf('#') + 1).split('&')

  for (let i = 0; i < hashes.length; i++) {
    const hash = hashes[i].split('=')
    if (hash.length > 1) {
      vars[hash[0]] = hash[1]
    } else {
      vars[hash[0]] = null
    }
  }

  return vars
}

export const getIdFromPath = () => {
  const pathname = window.location.pathname
  let id: string = ''
  const matches = pathname.match(new RegExp(/\s+/gi))
  if (matches && matches.length) {
    id = matches[0]
  }
  return id
}

export const replacePlaceholders = (str: string, mapObj: any) =>
  str.replace(
    new RegExp(
      Object.keys(mapObj)
        .map((key) => `{${key}}`)
        .join('|'),
      'gi',
    ),
    (matched) => mapObj[matched.substring(1, matched.length - 1)],
  )

export const replaceId = (str: string, id: string) => str.replace(':id', id)

export const replaceQueryParams = (url: string, params: any) => `${url}?${new URLSearchParams(params).toString()}`

export const replacePathParams = (url: string, params: any) => {
  let modifiedUrl = url
  Object.keys(params).forEach((key) => {
    modifiedUrl = modifiedUrl.replace(`{${key}}`, params[key])
  })
  return modifiedUrl
}

export const isDebug = (location: any) => {
  return location && location.search.lastIndexOf('debug=1') !== -1
}

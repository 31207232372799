import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import Components from '@cloudmeet/web-components'
import useQueryRequestV2 from '../common/hooks/useQueryRequestV2'
import useCommandRequestV2 from '../common/hooks/useCommandRequestV2'
import { OptionType } from '../common/models/OptionType'
import PageCard from '../common/components/PageCard'
import * as Style from './style'
import { Formik, FormikValues } from 'formik'
import * as Yup from 'yup'
import getWebhookForSave, { WebhookForSaveResponse } from '@cloudmeet/web-core/webhooks/getWebhookForSave'
import { NamedItem } from '@cloudmeet/web-core/common/models/namedItem'
import saveWebhook, { SaveWebhookRequest } from '@cloudmeet/web-core/webhooks/saveWebhook'
import * as Routes from '../appContainer/routes'

const { Button, Title, Input, Textarea, Multiselect } = Components.UI

export default () => {
  /** ------------------------------------ **
     Hooks declaration
     ** ------------------------------------ **/
  const history = useHistory()
  const params: any = useParams()
  const entityId = params.id ?? null
  const isEdit = Boolean(entityId)
  const pageTitle = isEdit ? 'Update' : 'Create'

  /** ------------------------------------ **
     Local States
     ** ------------------------------------ **/
  const [initialValues] = useQueryRequestV2<WebhookForSaveResponse | null>(null, () =>
    getWebhookForSave(entityId ?? ''),
  )
  const [sendCommandRequest, isSaving] = useCommandRequestV2()

  const toOptionType = (item: NamedItem): OptionType => ({
    label: item.name,
    value: item.id,
  })
  /** ------------------------------------ **
     Event Handlers
     ** ------------------------------------ **/
  const onChangesSave = async (values: any) => {
    const request: SaveWebhookRequest = { ...values, id: entityId }
    const result = await sendCommandRequest(() => saveWebhook(request))
    if (result.completed) {
      history.push(Routes.WEBHOOKS)
    }
  }

  /** ------------------------------------ **
     Main Component
     ** ------------------------------------ **/
  return (
    <>
      {initialValues && (
        <PageCard>
          <div className={Style.container}>
            <div>
              <Title value={`${pageTitle} webhook`} type="title" />
              <Title value={`Enter webhook details`} type="small-title" />
            </div>
            <div className={Style.formBody}>
              <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={ValidationSchema}
                onSubmit={(values: FormikValues) => onChangesSave(values)}
              >
                {(form) => {
                  return (
                    <>
                      <Input {...form} name={'url'} label={'Url'} />
                      <Textarea name={'description'} {...form} label={'Description(optional)'} />
                      <Multiselect
                        {...form}
                        label={'Events'}
                        name={'events'}
                        options={initialValues.availableEvents.map((item) => toOptionType(item))}
                      />
                      <Button
                        loading={isSaving}
                        disabled={isSaving || !form.isValid}
                        label={`${pageTitle} webhook`}
                        type="normal"
                        onClick={(e) => form.handleSubmit()}
                      />
                    </>
                  )
                }}
              </Formik>
            </div>
          </div>
        </PageCard>
      )}
    </>
  )
}

export const ValidationSchema = Yup.object().shape({
  url: Yup.string()
    .test('test-is-url', 'Not a valid url', function (value) {
      const { path, createError } = this

      return validURL(value) || createError({ path, message: 'Not a valid url' })
    })
    .required('Url is required')
    .nullable(),
})

const validURL = (string: any) => {
  try {
    return Boolean(new URL(string))
  } catch (e) {
    return false
  }
}

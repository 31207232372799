import { css } from '@emotion/css'

export const container = css`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 320px;
  max-height: 240px;
  margin: auto;
`

import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import Components from '@cloudmeet/web-components'

import PageCard from '../common/components/PageCard'
import * as Routes from '../appContainer/routes'
import * as Style from './style'
import { Formik, FormikValues } from 'formik'
import * as Yup from 'yup'
import useQueryRequest from '../common/hooks/useQueryRequest'
import useCommandRequest from '../common/hooks/useCommandRequest'
import {
  deleteRoomParticipant,
  getRoomDetails,
  getRoomParticipants,
  RoomDetailsResponse,
  RoomParticipantDto,
} from '@cloudmeet/web-core/rooms/api'
import { PagedList } from '@cloudmeet/web-core/common/models/api'
import { SearchFilter } from '@cloudmeet/web-core/common/models/searchFilter'
import VideoConfig from '@cloudmeet/web-core/common/config/video'
import { replaceId } from '@cloudmeet/web-core/common/utils/urlUtils'
import { toFullDateFromString } from '@cloudmeet/web-core/common/utils/dateUtils'
import { success } from '@cloudmeet/web-components/src/utils/notifications'

/** ------------------------------------ **
 Global declarations
 ** ------------------------------------ **/
const { Button, Table, TableActions, DeleteModal, Input } = Components.UI
const { Colors } = Components.Styles

export default () => {
  /** ------------------------------------ **
     Hooks declaration
     ** ------------------------------------ **/
  const history = useHistory()
  const params: any = useParams()
  const roomId = params.id

  /** ------------------------------------ **
   Local states
   ** ------------------------------------ **/
  const { isLoading: isRoomParticipantsLoading, sendQueryRequest: sendGetRoomParticipantsRequest } = useQueryRequest()
  const { sendQueryRequest: sendGetRoomDetailsRequest } = useQueryRequest()
  const { isLoading: isDeleting, sendCommandRequest: sendDeleteRoomParticipantRequest } = useCommandRequest()

  const [itemToDelete, setItemToDelete] = useState<any>(null)
  const [roomDetails, setRoomDetails] = useState<RoomDetailsResponse | null>(null)
  const [participantsPageList, setParticipantsPageList] = useState<PagedList<RoomParticipantDto> | null>()
  const [filter, setFilter] = useState<SearchFilter>({
    pageNumber: 1,
    pageSize: 20,
    orderColumn: 'createdAt',
    orderDescending: true,
    q: '',
  })

  const updateRoomParticipants = async () => {
    const response = await sendGetRoomParticipantsRequest<PagedList<RoomParticipantDto>>(() =>
      getRoomParticipants(roomId, filter),
    )
    setParticipantsPageList(response)
  }
  /** ------------------------------------ **
     Effects
     ** ------------------------------------ **/
  useEffect(() => {
    ;(async () => {
      const roomDetails = await sendGetRoomDetailsRequest<RoomDetailsResponse>(() => getRoomDetails(roomId))
      setRoomDetails(roomDetails)
    })()
  }, [])

  useEffect(() => {
    ;(async () => {
      await updateRoomParticipants()
    })()
  }, [filter])

  /** ------------------------------------ **
     Event Handlers
     ** ------------------------------------ **/
  const onTableChange = (pagination: any, filters: any, sorter: any) => {
    setFilter({
      ...filter,
      pageNumber: pagination.current,
      pageSize: pagination.pageSize,
      orderColumn: sorter.field || 'createdAt',
      orderDescending: sorter.order !== 'ascend',
    })
  }

  const ParticipantTableActions = ({ record }: any) => {
    const actions: any = {}

    if (!roomDetails?.presentationEnded) {
      actions.Presentation = [
        {
          icon: 'ri-slideshow-line',
          title: 'Join Presentation',
          onClick: () => {
            window
              .open(
                `${VideoConfig.videoURL}?roomId=${roomDetails?.id}&roomKey=${roomDetails?.publicAccessKey}&participantKey=${record.publicAccessKey}`,
                '_blank',
              )
              ?.focus()
          },
        },
        {
          icon: 'ri-file-copy-line',
          title: 'Copy invitation link',
          onClick: () => {
            return navigator.clipboard
              .writeText(
                `${VideoConfig.videoURL}?roomId=${roomDetails?.id}&roomKey=${roomDetails?.publicAccessKey}&participantKey=${record.publicAccessKey}`,
              )
              .then(() => {
                success('Copied')
              })
          },
        },
      ]
    }

    if (roomDetails?.presentationEnded) {
      actions.Presentation = [
        {
          icon: 'ri-booklet-line',
          title: 'View notes',
          onClick: () => {
            window
              .open(
                `${VideoConfig.videoURL}/attendee/notes/${roomDetails.id}?roomKey=${roomDetails.publicAccessKey}&participantKey=${record.publicAccessKey}`,
                '_blank',
              )
              ?.focus()
          },
        },
      ]
    }

    if (!roomDetails?.presentationStarted) {
      actions.Actions = [
        {
          icon: 'ri-pencil-line',
          title: 'Edit',
          onClick: () => {
            history.push(`${replaceId(Routes.PARTICIPANTS_MANAGE, roomId)}?pid=${record.id}`)
          },
        },
      ]
    }

    if (record?.canBeDeleted) {
      if (!actions.Actions) {
        actions.Actions = []
      }

      actions.Actions.push({
        icon: 'ri-delete-bin-line',
        title: 'Delete',
        color: Colors.SunsetOrange,
        onClick: () => {
          setItemToDelete(record)
        },
      })
    }

    return <TableActions actions={actions} />
  }

  const columns: any = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Role',
      dataIndex: 'roleName',
      key: 'RoleName',
    },
    {
      title: 'Is host?',
      dataIndex: 'isHost',
      key: 'isHost',
      align: 'center',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: (_: any, record: any) => (record.isHost ? 'yes' : 'no'),
    },
    {
      title: 'Joined?',
      dataIndex: 'joinedAt',
      key: 'joinedAt',
      align: 'center',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: (_: any, record: any) => (record.joinedAt ? 'yes' : 'no'),
    },
    {
      title: 'Left room?',
      dataIndex: 'leftRoom',
      key: 'leftRoom',
      align: 'center',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: (_: any, record: any) => (record.leftRoom ? 'yes' : 'no'),
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: (_: any, record: any) => toFullDateFromString(record.createdAt),
    },
    {
      width: '200px',
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      render: (_: any, record: any) => <ParticipantTableActions record={record} />,
    },
  ]

  const columnToSort: any = columns.find((c: any) => c.dataIndex === filter.orderColumn)
  columnToSort.sortOrder = filter.orderDescending ? 'descend' : 'ascend'

  const actions = [
    <div className={Style.filterContainer}>
      <Formik
        enableReinitialize
        initialValues={filter}
        validationSchema={Yup.object()}
        onSubmit={(values: FormikValues) => {
          setFilter({
            ...filter,
            pageNumber: 1,
            q: values.q,
          })
        }}
      >
        {(form) => (
          <>
            <Input
              name={'q'}
              {...form}
              placeholder={'Search'}
              handleSubmit={() => {
                form.handleSubmit()
              }}
            />
          </>
        )}
      </Formik>
      <Button
        label="New Participant"
        type="normal"
        icon={<i className="ri-add-line mr-4"></i>}
        onClick={() => {
          history.push(replaceId(Routes.PARTICIPANTS_MANAGE, roomId))
        }}
      />
      ,
    </div>,
  ]

  /** ------------------------------------ **
     Main Component
     ** ------------------------------------ **/
  const pageTitle = roomDetails ? `Participants for '${roomDetails.name}'` : ''
  return (
    <>
      {roomDetails && participantsPageList && (
        <PageCard title={pageTitle} actions={actions}>
          <div>
            <Table
              loading={isRoomParticipantsLoading}
              pagination={{
                total: participantsPageList.totalItemCount,
                pageSize: participantsPageList.pageSize,
                current: participantsPageList.pageNumber,
                showSizeChanger: true,
                pageSizeOptions: ['5', '10', '20', '30', '40', '50'],
              }}
              dataSource={participantsPageList.items}
              columns={columns}
              onChange={onTableChange}
            />
            <DeleteModal
              visible={itemToDelete != null}
              name={itemToDelete?.name}
              onCancelClick={() => {
                setItemToDelete(null)
              }}
              onDeleteClick={async () => {
                const result = await sendDeleteRoomParticipantRequest(() => deleteRoomParticipant(itemToDelete.id))
                if (!result.hasErrors) {
                  setItemToDelete(null)
                  await updateRoomParticipants()
                }
              }}
              deleteButtonLoading={isDeleting}
            />
          </div>
        </PageCard>
      )}
    </>
  )
}

import React from 'react'
import { Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { cx } from '@emotion/css'

import * as Style from './style'
import { TableAction, TablePaginationConfig } from 'antd/lib/table/interface'

type IProps = {
  dataSource: any
  columns: ColumnsType<any>
  className?: string
  pagination?: false | TablePaginationConfig
  actions?: {
    [key: string]: TableAction[]
  }
  onChange?: (pagination: any, filters: any, sorter: any) => void
  loading?: boolean
}

const Component = ({ dataSource, columns, className, pagination, onChange, loading }: IProps) => {
  return (
    <Table
      loading={loading}
      onChange={onChange}
      dataSource={dataSource}
      columns={columns}
      className={cx(Style.table, className)}
      pagination={pagination}
    />
  )
}

export default Component

import { css } from '@emotion/css'

export const deactivateModalHeader = css`
  display: flex;
  align-items: center;
  max-width: 400px;
  & span {
    margin-top: -8px;
    margin-right: 10px;
  }
`

export const modalActions = css`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column-reverse;
  margin-top: 25px;
`

export const modalContentText = css`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #535c68;
  margin-left: 30px;
  max-width: 280px;
`

export const modalActionsRight = css`
  display: flex;
  max-width: 80px;
  display: flex;
  justify-content: flex-end !important;
  align-items: flex-end !important;
  flex-direction: column-reverse;
`

export const modalActionsRightContainer = css`
  display: flex;
  max-width: 250px;
  display: flex;
  justify-content: space-between;
  align-content: space-between;
  & button {
    margin-left: 8px !important;
  }
`

export const modalContent = css`
  margin: 15px 0;
  max-width: 350px;
`

import React from 'react'
import { Form, Select } from 'antd'

import * as Style from './style'

const { Option } = Select

export type OptionType = {
  label: string
  value: any
}

const SelectInput = ({
  name,
  labelInline,
  label,
  errors,
  showSearch,
  placeholder,
  options,
  defaultOption,
  touched,
  setFieldValue,
  setFieldTouched,
  values,
  disabledOnValueProvided,
  disabled,
  initialValues,
  allowClear,
  onChange,
}: {
  name: any
  label?: any
  labelInline?: boolean
  errors?: any
  showSearch?: boolean
  placeholder?: string
  options: OptionType[]
  defaultOption?: any
  touched?: any
  setFieldValue: Function
  setFieldTouched: Function
  values: any
  disabledOnValueProvided?: boolean
  disabled?: boolean
  initialValues?: any
  allowClear?: boolean
  onChange?: (value: any) => void
}) => (
  <Form.Item
    hasFeedback={!!(errors[name] && touched[name])}
    validateStatus={errors[name] && touched[name] && 'error'}
    help={errors[name] && touched[name] ? errors[name] : null}
    label={label}
    className={labelInline ? Style.inlineLabel : ''}
  >
    <Select
      className={Style.select}
      allowClear={allowClear}
      defaultValue={defaultOption !== null && defaultOption !== undefined ? defaultOption : initialValues[name]}
      disabled={disabledOnValueProvided ? !!initialValues[name] : Boolean(disabled)}
      showSearch={showSearch}
      placeholder={placeholder}
      onChange={(value: string) => {
        setFieldValue(name, value)
        if (onChange) {
          onChange(value)
        }
      }}
      onBlur={() => setFieldTouched(name)}
      notFoundContent="No Data"
      onSearch={() => {}}
      value={values[name]}
    >
      {options.map((option: OptionType) => (
        <Option key={option.value} value={option.value}>
          {option.label}
        </Option>
      ))}
    </Select>
  </Form.Item>
)

export default SelectInput

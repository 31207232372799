import { css } from '@emotion/css'

import Styles from '../../../styles'
import './overrides.css'

const { Colors } = Styles

export const popover = css``

export const actionGroup = css`
  margin-bottom: 10px;
`

export const menuItem = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  height: 33px;
  &:hover {
    background: rgba(250, 250, 250, 0.7);
    margin-left: -15px;
    padding-left: 15px;
    padding-right: 15px;
    margin-right: -15px;
  }
`
export const menuIcon = css`
  margin-right: 10px;
  display: flex;
  justify-self: center;
`

export const menuTitle = css``

export const actionBtn = css`
  width: 24px;
  height: 24px;
  &:hover {
    border-radius: 100% !important;
    background: rgba(200, 200, 200, 0.2) !important;
  }
`

export const category = css`
  margin-bottom: 5px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  display: flex;
  align-items: center;

  /* Gray / gray-6 */

  color: #98a9c0;
`

import { css } from '@emotion/css'
import Styles from '../../styles'

const { Colors } = Styles

export const tabs = css`
  & .ant-tabs-tab {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px !important;
    line-height: 24px !important;
    color: ${Colors.Gray8} !important;
  }
  & .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${Colors.PrimaryColor} !important;
  }
  & .ant-tabs-tab:hover {
    color: ${Colors.PrimaryColor} !important;
  }
  & .ant-tabs-ink-bar {
    background: ${Colors.PrimaryColor} !important;
  }
`

export const tabsWithBackground = css`
  ${tabs}
  & .ant-tabs-nav {
    background: ${Colors.Gray2} !important;
    padding: 0 0 0 15px !important;
  }
`

import { css } from '@emotion/css'
import Styles from '../../styles'

const { Colors } = Styles

export const container = css`
  & .ant-btn-primary {
    background: ${Colors.PrimaryColor} !important;
  }
`

export const okButton = css`
  background: ${Colors.PrimaryColor} !important;
  border-color: ${Colors.PrimaryColor} !important;
`

export const okRedButton = css`
  background: ${Colors.Red} !important;
  border-color: ${Colors.Red} !important;
`

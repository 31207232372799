import React, { useEffect, useState } from 'react'
import { Formik, FormikValues } from 'formik'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import Components from '@cloudmeet/web-components'

import PageCard from '../common/components/PageCard'
import * as Style from './style'
import { RoomTypeInfo, RoomTypesList } from './schemas'

import { RoomType } from '@cloudmeet/web-core/rooms/models'
import { getRoomForSave, saveRoom } from '@cloudmeet/web-core/rooms/api'
import * as Yup from 'yup'
import { OptionType } from '../common/models/OptionType'
import useCommandRequestV2 from '../common/hooks/useCommandRequestV2'
import useQueryRequestV2 from '../common/hooks/useQueryRequestV2'

/** ------------------------------------ **
 Global declarations
 ** ------------------------------------ **/
const { Button, Title, Input, Switch, Select } = Components.UI

export default () => {
  /** ------------------------------------ **
     Hooks declaration
     ** ------------------------------------ **/
  const history = useHistory()
  const location = useLocation()
  const params: any = useParams()
  const type = parseInt(new URLSearchParams(location.search).get('type') ?? `${RoomType.Keynote}`)
  const roomTypeInfo: RoomTypeInfo | undefined = RoomTypesList.find((x) => x.id === type)
  const entityId = params.id ?? ''
  const isEdit = Boolean(entityId)
  const pageTitle = isEdit ? 'Update' : 'Create'

  /** ------------------------------------ **
     Local States
     ** ------------------------------------ **/
  const [initialValues] = useQueryRequestV2(null, () => getRoomForSave(entityId))
  const [sendCommandRequest, isSaving] = useCommandRequestV2()

  const toOptionType = (recordingOption: any): OptionType => ({
    label: recordingOption.name,
    value: recordingOption.id,
  })

  /** ------------------------------------ **
     Event Handlers
     ** ------------------------------------ **/
  const onChangesSave = async (values: any) => {
    if (roomTypeInfo) {
      const result = await sendCommandRequest(() =>
        saveRoom({
          ...values,
          type,
        }),
      )
      if (!result.hasErrors) {
        history.push(roomTypeInfo.url)
      }
    }
  }

  /** ------------------------------------ **
     Main Component
     ** ------------------------------------ **/
  return (
    <>
      {initialValues && (
        <PageCard>
          <div className={Style.container}>
            <div>
              <Title value={`${pageTitle} ${roomTypeInfo?.single}`} type="title" />
              <Title value={`Enter ${roomTypeInfo?.single} details`} type="small-title" />
            </div>
            <div className={Style.formBody}>
              <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={RoomCreateValidationSchema}
                onSubmit={(values: FormikValues) => onChangesSave(values)}
              >
                {(form) => {
                  return (
                    <>
                      <Input {...form} name={'name'} label={'Name'} />
                      <Select
                        {...form}
                        label={'Recording'}
                        name={'recordingOption'}
                        options={initialValues.availableRecordingOptions.map((item) => toOptionType(item))}
                      />
                      <Switch {...form} label="Private?" labelInline={true} name="privateRoom" />
                      <Button
                        loading={isSaving}
                        disabled={isSaving}
                        label={`${pageTitle} ${roomTypeInfo?.single}`}
                        type="normal"
                        onClick={(e) => form.handleSubmit()}
                      />
                    </>
                  )
                }}
              </Formik>
            </div>
          </div>
        </PageCard>
      )}
    </>
  )
}

export const RoomCreateValidationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required').nullable(),
})

import callApi from '../common/api/apiRequest'
import { WebhookEvent } from './models'
import { NamedItem } from '../common/models/namedItem'

export type WebhookForSaveResponse = {
  id: string
  url: string
  description: string
  events: WebhookEvent[]
  availableEvents: NamedItem[]
}

export default async (id?: string): Promise<WebhookForSaveResponse> => {
  return callApi<WebhookForSaveResponse>('get', `webhooks/forSave/${id}`, {})
}

import UIComponents from './components'
import Styles from './styles'
import Utils from './utils'
import Hooks from './hooks'

export default {
  UI: UIComponents,
  Styles,
  Utils,
  Hooks,
}

import { css } from '@emotion/css'
import Styles from '../../styles'

const { Colors } = Styles

export const inline = css`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

export const block = css`
  display: flex;
  flex-direction: column;
`

export const key = css`
  padding: 0 15px 0 0;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: #808996;
  margin-bottom: 5px;
`

export const value = css`
  padding: 0;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: ${Colors.DarkGray};
`

import React from 'react'
import ReactAvatar from 'react-avatar'
import * as Style from './style'
import * as Colors from '../../../styles/colors'

type IProps = {
  name: string
  color?: string
  size?: 'large' | 'small'
}

const Component = ({ name, color, size }: IProps) => {
  return (
    <ReactAvatar
      size={size === 'large' ? '48' : '32'}
      fgColor={Colors.White}
      round
      name={name}
      color={color || Colors.PrimaryColor}
    />
  )
}

export default Component

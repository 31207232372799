import { css } from '@emotion/css'
import Styles from '../../../styles'

const { Colors } = Styles

export const container = css`
  display: flex;
  align-items: center;
`

export const user = css`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 10px;
  &:hover {
    background: rgba(200, 200, 200, 0.2);
    transition: 0.3s linear background;
    padding: 10px;
    border-radius: 50px;
  }
`

export const name = css`
  margin-left: 10px;
  font-family: Inter, serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: ${Colors.Gray8};
  display: flex;
`

export const arrow = css`
  margin-left: 13px;
  display: flex;
  align-items: center;
`

export const userMenu = css`
  padding: 0;
  display: flex;
  align-items: center;
`

export const userMenuItem = css`
  padding: 15px;
  width: 100%;
  cursor: pointer;
  color: ${Colors.Gray9};
  &:hover {
    background-color: ${Colors.Gray2};
  }
`

export const userMenuPopover = css`
  display: flex;
  margin-left: -100px;
  margin-top: 5px;
`

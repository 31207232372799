import callApi from '../common/api/apiRequest'
import { RoomType } from './models'

export type RoomUsageResponse = {
  id: string
  name: string
  totalVideoConferenceParticipants: number
  videoConferenceParticipantsCost: number
  totalVideoConferenceRecordingMinutes: number
  videoConferenceRecordingCost: number
  totalVideoConferenceLivestreamMinutes: number
  videoConferenceLivestreamCost: number
  broadcastLivestreamGb: number
  broadcastLivestreamCost: number
  totalLivestreamCost: number
  broadcastRecordingGb: number
  broadcastRecordingCost: number
  totalCost: number
  roomType: RoomType
  totalParticipantsJoinedOnRealtimeStore: number
  totalParticipantsJoinedOnRealtimeStoreCost: number
}

export const getRoomUsage = async (id: string) => {
  return callApi<RoomUsageResponse>('get', `rooms/${id}/usage`)
}

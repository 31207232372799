import React, { MouseEventHandler, useState } from 'react'
import { Popover } from 'antd'

import UserAvatar from '../UserAvatar'
import Icon from '../../Icon'
import * as Style from './style'
import './overrides.css'

type IProps = {
  userName: string
  onUserIconClick: MouseEventHandler<HTMLElement>
  onUserArrowClick: MouseEventHandler<HTMLElement>
  onLogoutClick: MouseEventHandler<HTMLElement>
}

const UserMenu = ({ onLogoutClick }: { onLogoutClick: MouseEventHandler<HTMLElement> }) => {
  return (
    <div className={Style.userMenu}>
      <div className={Style.userMenuItem} onClick={onLogoutClick}>
        Logout
      </div>
    </div>
  )
}

const Component = ({ userName, onUserArrowClick, onUserIconClick, onLogoutClick }: IProps) => {
  const [userMenuVisible, setUserMenuVisible] = useState(false)
  return (
    <div className={`${Style.container}`}>
      <div className={Style.user} onClick={onUserIconClick}>
        <UserAvatar name={userName} size={'small'} />
        <span className={Style.name}>{userName}</span>
      </div>
      <Popover
        placement="bottom"
        visible={userMenuVisible}
        content={<UserMenu onLogoutClick={onLogoutClick} />}
        trigger="click"
      >
        <div className={Style.arrow} onClick={() => setUserMenuVisible(!userMenuVisible)}>
          <Icon iconName="arrow-down-dark-gray.svg" width={12.7} height={7.8} onClick={onUserArrowClick} />
        </div>
      </Popover>
    </div>
  )
}

export default Component

import Moment from 'moment'

export const YEAR_FORMAT = 'YYYY'
export const DATE_FORMAT = 'DD/MM/YYYY'
export const TIME_FORMAT = 'HH:mm'

export const now = () => Moment()
export const toMoment = (d?: number) => (d ? Moment(d * 1000) : Moment())
export const toMomentFromString = (dateAsString?: string) => Moment(dateAsString)
export const toServerMoment = (d?: Moment.Moment): number => (Boolean(d) ? Moment(d).unix() : -1)
export const toYear = (d: Moment.Moment) => d.format(YEAR_FORMAT)
export const toFullDateFromString = (d: string) => Moment(d).format(DATE_FORMAT)
export const toFullDate = (d: Moment.Moment) => d.format(DATE_FORMAT)
export const toTime = (d: Moment.Moment) => d.format(TIME_FORMAT)
export const getCurrentYear = () => toYear(Moment())
export const compareDates = (a: Moment.Moment, b: Moment.Moment) => Moment(a).milliseconds() - Moment(b).milliseconds()
export const diffWithNow = (dateAsString: string) => Moment.duration(Moment().utc().diff(Moment(dateAsString).utc()))
export const diffWithNowReversed = (dateAsString: string): Moment.Duration =>
  Moment.duration(Moment(dateAsString).utc().diff(Moment().utc()))
export const isDateAfterNow = (dateAsString: string): boolean =>
  Moment.duration(Moment(dateAsString).utc().diff(Moment().utc())).asSeconds() > 0
export const isDateBeforeNow = (dateAsString: string): boolean =>
  Moment.duration(Moment(dateAsString).utc().diff(Moment().utc())).asSeconds() < 0
export const diffWithNowFormatted = (dateAsString: string, format: string): string =>
  Moment.utc(Moment().utc().diff(Moment(dateAsString).utc())).format(format)
export const diffWithNowReverseFormatted = (dateAsString: string, format: string): string => {
  const result = Moment(dateAsString).utc().diff(Moment().utc())
  return result >= 0 ? Moment.utc(result).format(format) : ''
}

import Axios, { AxiosPromise } from 'axios'
import Config from '../config'

export const Methods = {
  POST: 'post',
  GET: 'get',
  PUT: 'put',
  DELETE: 'delete',
}

const resolveUrl = (url: string): string => {
  return url.includes('http') ? url : `${Config.Api.baseURL}/${url}`
}
/**
 * Http Client
 */
export default async <T = any>(
  method: any,
  url: string,
  body?: any,
  authToken?: string | null,
  headers?: any | null,
): Promise<T> => {
  if (url.search(/\{\w+\}/) >= 0) {
    throw new TypeError(`HttpClient invalid argument: url=${url} contains a placeholder`)
  }
  const isFileUpload = body instanceof FormData

  if (!headers)
    headers = {
      'Content-Type': isFileUpload ? 'multipart/form-data' : 'application/json',
    }

  const token = localStorage.getItem('token')
  if (authToken || token) {
    headers['Authorization'] = `Bearer ${authToken || token}`
  }

  try {
    const response = await Axios({
      method,
      url: resolveUrl(url),
      headers,
      data: method !== 'get' ? body : undefined,
      params: method === 'get' ? body : null,
    })
    return response.data
  } catch (e) {
    throw e
  }
}

import { css } from '@emotion/css'
import Styles from '../../styles'

const { Colors } = Styles

export const modal = css`
  width: fit-content !important;

  & .ant-modal-content {
    border-radius: 6px !important;
  }
  & .ant-modal-header {
    border-radius: 6px !important;
  }
  & .ant-modal-body {
    border-radius: 6px !important;
  }

  & .ant-modal-close-x {
    display: none;
  }

  & .ant-btn-primary {
    background: ${Colors.PrimaryColor} !important;
  }
`

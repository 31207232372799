import { css } from '@emotion/css'
import Components from '@cloudmeet/web-components'

export const appHeader = css`
  font-weight: normal;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: solid 1px #eaeaea;
  color: ${Components.Styles.Colors.PrimaryColor};
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100px;
  display: flex;
  background-color: white;
  & p {
    font-weight: bold;
    margin: 0;
  }
`

export const bodyContainer = css`
  display: flex;
  width: 100%;
  height: fit-content;
  background-color: #f7f8fa;
`

export const body = css`
  display: flex;
  width: 100%;
`

import { SharedStateAction, SharedPersistedStateAction } from './actions'
import produce from 'immer'
import { AnyAction } from 'redux'

export const sharedState = (state: any = {}, action: any) => {
  switch (action.type) {
    case SharedStateAction:
      return produce((draftState: any, action: AnyAction) => {
        draftState[action.payload.name] = action.payload.value
      })(state, action)
    default:
      return state
  }
}

export const sharedPersistedState = (state: any = {}, action: any) => {
  switch (action.type) {
    case SharedPersistedStateAction:
      return produce((draftState: any, action: AnyAction) => {
        draftState[action.payload.name] = action.payload.value
      })(state, action)
    default:
      return state
  }
}

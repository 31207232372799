import React from 'react'
import Icon from '@ant-design/icons'
import { cx } from '@emotion/css'
import * as Style from './style'

export interface IProps {
  iconName: string
  width?: number
  height?: number
  className?: string
  onClick?: Function
  isBase64?: boolean
  disabled?: boolean
}

const Component: React.FunctionComponent<IProps> = (props: IProps) => {
  const { width, height, iconName, className, onClick, isBase64, disabled } = props
  const classNames = cx(className, Style.icon)
  return (
    <Icon
      className={classNames}
      disabled={disabled}
      onClick={(event: React.MouseEvent<HTMLElement>) => {
        if (onClick) onClick()
      }}
      component={() => (
        <img
          src={isBase64 ? `data:image/png;charset=utf-8;base64,${iconName}` : `/assets/images/${iconName}`}
          width={width}
          height={height}
        />
      )}
    />
  )
}

export default Component

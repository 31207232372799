import { applyMiddleware, createStore, compose, StoreEnhancer, Middleware, combineReducers } from 'redux'

import { createLogger } from 'redux-logger'
import { routerMiddleware, connectRouter } from 'connected-react-router'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { createBrowserHistory } from 'history'
import * as commonReducers from '@cloudmeet/web-core/common/reducers'

import pkg from '../package.json'

export const history = createBrowserHistory()

/**
 * React router reducer
 */
export const rootReducer = combineReducers({
  ...commonReducers,
  router: connectRouter(history),
})

/**
 * Redux persistence configuration
 */
const persistConfig = {
  key: pkg.name,
  storage,
  whitelist: ['sharedPersistedState'],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

/**
 * Definition of cross-environment middlewares
 */
const middlewares: Middleware[] = [routerMiddleware(history)]

/**
 * Development environment middlewares
 */
if (process.env.NODE_ENV === 'development') {
  middlewares.push(createLogger({ collapsed: true }))
}

/**
 * Middleware enhancer composition
 */
// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const enhancer: StoreEnhancer = composeEnhancers(applyMiddleware(...middlewares))

/**
 * Create redux-store with
 * - index
 * - initialReducers
 * - enhancers
 */
const store: any = createStore(persistedReducer, enhancer)

export const persistedStore = persistStore(store)
export const initialState = {}

export default store

import React from 'react'
import * as Style from './style'
import { Tooltip } from 'antd'
import { RenderFunction } from 'antd/lib/tooltip'

type Props = {
  title: React.ReactNode | RenderFunction
  overlay?: React.ReactNode | RenderFunction
  children?: React.ReactNode
}

const Component = (props: Props) => <Tooltip title={props.title}>{props.children}</Tooltip>

export default Component

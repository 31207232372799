import callApi from '../common/api/apiRequest'
import * as Const from './constants'
import { RoomFilter, RoomParticipantPermission, RoomRecordingOption, RoomType } from './models'
import { PagedList } from '../common/models/api'
import { NamedItem } from '../common/models/namedItem'
import { SearchFilter } from '../common/models/searchFilter'
import { Role } from '../tenants/models'

const BASE_URL = 'rooms'

export type GetDetailsForParticipantRequest = {
  roomId: string
  roomKey: string
  participantKey: string
  participantName?: string
}

type Theme = {
  primaryColor: string
  backgroundColor: string
}

type ParentRoom = {
  roomId: string
  roomKey: string
}

export type GetDetailsForParticipantResponse = {
  participantKey: string
  participantName: string
  role: Role
  videoConferenceJwt: string
  isHost: false
  isHostForParentRoom: boolean
  kickedOut: boolean
  isPublicParticipant: boolean
  roomId: string
  roomName: string
  roomKey: string
  roomType: RoomType
  videoConferenceRoomId: string
  theme: Theme
  recordingOption: RoomRecordingOption
  requiresRecording: boolean
  noteTimerStartsAt?: string
  parentRoom?: ParentRoom
  isBreakoutRoom: boolean
  presentationStarted: boolean
  presentationEnded: boolean
  presentersChatRoomId: string
  isOnlyViewer: boolean
  canStartLivestream: boolean
  canRecordOnVideoConference: boolean
  canAddNotes: boolean
  canManageQuestions: boolean
  canStartPresentation: boolean
  canEndPresentation: boolean
  canStartBreakoutRooms: boolean
  canKickOut: boolean
  canListBreakoutRooms: boolean
  canCloseBreakoutRooms: boolean
  canChangeHost: boolean
  hasStartLivestream: boolean
  roleCustomLabel: string
}

export const getDetailsForParticipant = async (request: GetDetailsForParticipantRequest) => {
  return callApi<GetDetailsForParticipantResponse>(
    'get',
    `${Const.ROOMS_GET_ROOM_DETAILS_FOR_PARTICIPANT_URL}/${request.roomId}`,
    {
      roomKey: request.roomKey,
      participantKey: request.participantKey,
      participantName: request.participantName,
    },
  )
}

export type RoomDto = {
  name: string
  privateRoom: string
  recordingOption: RoomRecordingOption
  publicAccessKey: string
  createdAt: Date
  presentationStartedAt?: Date
  presentationEndedAt?: Date
  totalParticipants: number
  presentationStarted: boolean
  presentationEnded: boolean
}
export const getRooms = async (request: RoomFilter) => {
  return callApi<PagedList<RoomDto>>('get', `${Const.ROOMS_GET_URL}`, request)
}

export const deleteRoom = async (id: string) => {
  return callApi<void>('delete', `${Const.ROOMS_GET_URL}`, { id })
}

export type GetRoomForSaveResponse = {
  id?: string
  name: string
  privateRoom: boolean
  type: RoomType
  recordingOption: RoomRecordingOption
  availableRecordingOptions: NamedItem[]
}

export const getRoomForSave = async (id?: string) => {
  return callApi<GetRoomForSaveResponse>('get', `${Const.ROOMS_GET_FOR_SAVE_URL}/${id}`)
}

export type SaveRoomRequest = {
  id?: string
  name: string
  privateRoom: boolean
  type: RoomType
  recordingOption: RoomRecordingOption
}

export const saveRoom = async (values: SaveRoomRequest) => {
  return callApi<GetRoomForSaveResponse>('post', `${Const.ROOMS_GET_URL}`, values)
}

export type RoomParticipantDto = {
  id: string
  name: string
  email: string
  roleName: string
  createdAt: Date
  publicAccessKey: string
  isHost: boolean
  joinedAt: string
}

export const getRoomParticipants = async (roomId: string, filter: SearchFilter) => {
  return callApi<PagedList<RoomParticipantDto>>('get', `${Const.ROOMS_GET_PARTICIPANTS_URL}/${roomId}`, filter)
}

export type RoomDetailsResponse = {
  id: string
  name: string
  privateRoom: string
  recordSession: boolean
  recordingOption: RoomRecordingOption
  publicAccessKey: string
  createdAt: Date
  presentationStartedAt?: Date
  presentationEndedAt?: Date
  totalParticipants: number
  presentationStarted: boolean
  presentationEnded: boolean
}
export const getRoomDetails = async (id: string) => {
  return callApi<RoomDetailsResponse>('get', `${Const.ROOMS_GET_ROOM_DETAILS_BY_ID}/${id}`)
}

export const deleteRoomParticipant = async (id: string) => {
  return callApi<void>('delete', `${Const.ROOMS_DELETE_PARTICIPANT}`, { id })
}

export type RoomParticipantForSaveResponse = {
  id?: string
  name: string
  email: string
  roomId: string
  role: Role
  isHost: boolean
  availableRoles: NamedItem[]
}

export const getRoomParticipantForSave = async (id?: string) => {
  return callApi<RoomParticipantForSaveResponse>('get', `${Const.ROOMS_GET_ROOM_PARTICIPANTS_FOR_SAVE_URL}/${id}`)
}

export const saveRoomParticipant = async (values: any) => {
  return callApi<string>('post', `${Const.ROOMS_SAVE_PARTICIPANTS_URL}`, values)
}

type JoinPresentationRequest = {
  roomId: string
  roomKey: string
  participantKey: string
  conferenceId: string
  participantName: string
}

export type JoinPresentationResponse = {
  joinedAt: string
}
export const joinPresentation = async (values: JoinPresentationRequest) => {
  return callApi<JoinPresentationResponse>('put', `${Const.ROOMS_JOIN_PRESENTATION_URL}`, values)
}

type GetSubscriberTokenRequest = {
  roomId: string
  roomKey: string
  participantKey: string
}

export type GetSubscriberTokenResponse = {
  token: string
}
export const getSubscriberToken = async (values: GetSubscriberTokenRequest) => {
  return callApi<GetSubscriberTokenResponse>('get', `rooms/subscriberToken`, values)
}

type StartPresentationRequest = {
  roomId: string
  roomKey: string
  participantKey: string
  recordSession: boolean
}

export const startPresentation = async (values: StartPresentationRequest) => {
  return callApi<void>('put', `${Const.ROOMS_START_PRESENTATION_URL}`, values)
}

type EndPresentationRequest = {
  roomId: string
  roomKey: string
  participantKey: string
}
export const endPresentation = async (values: EndPresentationRequest) => {
  return callApi<void>('put', `${Const.ROOMS_END_PRESENTATION_URL}`, values)
}

type ChangeHostRequest = {
  roomId: string
  roomKey: string
  participantKey: string
  newHostParticipantKey: string
}
export const changeHost = async (values: ChangeHostRequest) => {
  return callApi<void>('put', `rooms/changeHost`, values)
}

type SetLivestreamStartedTimeRequest = {
  roomId: string
  roomKey: string
  participantKey: string
}
export const setLivestreamStartedTime = async (values: SetLivestreamStartedTimeRequest) => {
  return callApi<void>('put', `rooms/setLivestreamStartedTime`, values)
}

type SetRecordingStartedTimeRequest = {
  roomId: string
  roomKey: string
  participantKey: string
}
export const setRecordingStartedTime = async (values: SetRecordingStartedTimeRequest) => {
  return callApi<void>('put', `rooms/setRecordingStartedTime`, values)
}

type GetPublisherCredentialsRequest = {
  roomId: string
  roomKey: string
  participantKey: string
}
export type GetPublisherCredentialsResponse = {
  websocketUrl: string
  jwt: string
  publishingToken: string
}
export const getPublisherCredentials = async (values: GetPublisherCredentialsRequest) => {
  return callApi<GetPublisherCredentialsResponse>('get', `rooms/publisherCredentials/${values.roomId}`, {
    roomKey: values.roomKey,
    participantKey: values.participantKey,
  })
}

type GenerateVerifyAttendeeRoomCredentialsRequest = {
  roomKey: string
  participantKey: string
  roomId: string
  hostParticipantName: string
  attendeeParticipantKey: string
  attendeeParticipantName: string
  questionId: string
}

export type GenerateVerifyAttendeeRoomCredentialsResponse = {
  name: string
  alternativeName: string
  host: VerifyParticipantDto
  attendee: VerifyParticipantDto
}

export type VerifyParticipantDto = {
  participantKey: string
  name: string
  role: Role
  jwt: string
}

export const generateVerifyAttendeeRoomCredentials = async (request: GenerateVerifyAttendeeRoomCredentialsRequest) => {
  return callApi<GenerateVerifyAttendeeRoomCredentialsResponse>(
    'put',
    `rooms/generateVerifyAttendeeRoomCredentials`,
    request,
  )
}

type GenerateTemporaryAttendeeRoomCredentialsRequest = {
  roomKey: string
  participantKey: string
  roomId: string
  attendeeParticipantKey: string
  questionId: string
}

type GenerateTemporaryAttendeeRoomCredentialsResponse = {
  roomName: string
  roomSubject: string
  jwt: string
}
export const generateTemporaryAttendeeRoomCredentials = async (
  request: GenerateTemporaryAttendeeRoomCredentialsRequest,
) => {
  return callApi<GenerateTemporaryAttendeeRoomCredentialsResponse>(
    'put',
    `rooms/generateTemporaryAttendeeRoomCredentials`,
    request,
  )
}

export type RoomRecordingResponse = {
  downloadUrl: string
  duration: number
}
export const getRoomRecordings = async (roomId: string, roomKey: string) => {
  return callApi<RoomRecordingResponse>('get', `rooms/recordings/${roomId}?roomKey=${roomKey}`)
}

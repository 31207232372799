import React from 'react'
import { Card, Skeleton } from 'antd'

import * as Style from './style'

export interface IProps {
  active?: boolean
  loading?: boolean
  children?: React.ReactNode
  round?: boolean
}

const Component: React.FunctionComponent<IProps> = (props: IProps) => {
  return (
    <Skeleton active={props.active} className={Style.wrap} loading={props.loading} round={props.round}>
      {props.children}
    </Skeleton>
  )
}

export default Component

import { css } from '@emotion/css'
import Styles from '../../styles'

const { Colors } = Styles

export const inlineLabel = css``
export const switchContainer = css`
  & .ant-switch-checked {
    background-color: ${Colors.PrimaryColor} !important;
  }
`

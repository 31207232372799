import React, { useEffect, useState } from 'react'
import { Formik, FormikValues } from 'formik'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import Components from '@cloudmeet/web-components'
import * as Routes from '../appContainer/routes'
import PageCard from '../common/components/PageCard'
import * as Style from './style'
import {
  getRoomParticipantForSave,
  RoomParticipantForSaveResponse,
  saveRoomParticipant,
} from '@cloudmeet/web-core/rooms/api'
import useQueryRequest from '../common/hooks/useQueryRequest'
import useCommandRequest from '../common/hooks/useCommandRequest'
import { Role } from '@cloudmeet/web-core/tenants/models'
import { replaceId } from '@cloudmeet/web-core/common/utils/urlUtils'
import * as Yup from 'yup'

/** ------------------------------------ **
 Global declarations
 ** ------------------------------------ **/
const { Button, Title, Input, Select, Switch } = Components.UI

export default () => {
  /** ------------------------------------ **
     Hooks declaration
     ** ------------------------------------ **/
  const history = useHistory()
  const location = useLocation()
  const params: any = useParams()
  const roomId = params.id
  const participantId = new URLSearchParams(location.search).get('pid') ?? null

  /** ------------------------------------ **
     Local States
     ** ------------------------------------ **/
  const { sendQueryRequest } = useQueryRequest()
  const { isLoading: isSaving, sendCommandRequest } = useCommandRequest()

  const [initialValues, setInitialValues] = useState<RoomParticipantForSaveResponse | null>()
  const [showHostInput, setShowHostInput] = useState(false)
  const pageTitle = participantId ? 'Update participant' : 'Create participant'

  /** ------------------------------------ **
     Effects
     ** ------------------------------------ **/
  useEffect(() => {
    ;(async () => {
      const result = await sendQueryRequest<RoomParticipantForSaveResponse>(() =>
        getRoomParticipantForSave(participantId ?? ''),
      )
      setInitialValues(result)

      if (result) {
        setShowHostInput(result.role !== Role.Attendee)
      }
    })()
  }, [])

  /** ------------------------------------ **
     Event Handlers
     ** ------------------------------------ **/
  const onChangesSave = async (values: any) => {
    const result = await sendCommandRequest(() =>
      saveRoomParticipant({
        ...values,
        roomId,
        participantId,
      }),
    )

    if (!result.hasErrors) {
      history.push(replaceId(Routes.PARTICIPANTS, roomId))
    }
  }

  /** ------------------------------------ **
     Main Component
     ** ------------------------------------ **/

  return (
    <>
      {initialValues && (
        <PageCard>
          <div className={Style.container}>
            <div>
              <Title value={pageTitle} type="title" />
              <Title value="Enter participant details" type="small-title" />
            </div>
            <div className={Style.formBody}>
              <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={RoomParticipantValidationSchema}
                onSubmit={(values: FormikValues) => onChangesSave(values)}
              >
                {(form) => {
                  return (
                    <>
                      <Input {...form} name={'name'} label={'Name'} />
                      <Input {...form} name={'email'} label={'Email'} />
                      <Select
                        name="role"
                        label="Role"
                        options={initialValues.availableRoles.map((item: any) => ({
                          label: item.name,
                          value: item.id,
                        }))}
                        onChange={(value: any) => {
                          setShowHostInput(value !== Role.Attendee)
                          setInitialValues({
                            ...form.values,
                            role: value,
                          })
                        }}
                        {...form}
                      />
                      {showHostInput && <Switch label="Is host?" labelInline={true} name="isHost" {...form} />}
                      <Button
                        loading={isSaving}
                        disabled={isSaving}
                        label={pageTitle}
                        type="normal"
                        onClick={() => form.handleSubmit()}
                      />
                    </>
                  )
                }}
              </Formik>
            </div>
          </div>
        </PageCard>
      )}
    </>
  )
}

export const RoomParticipantValidationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required').nullable(),
  email: Yup.string().email('Email format is invalid').required('Email is required').nullable(),
})

import { useState } from 'react'
import CognitoConfig, { getCognitoUrl } from '@cloudmeet/web-core/common/config/cognito'
import Components from '@cloudmeet/web-components'
const { error } = Components.Utils.Notifications

type SendQueryRequestOptions = {
  showErrors: boolean
}

export default () => {
  const [isLoading, setIsLoading] = useState(false)

  const sendQueryRequest = async <T>(func: Function, opts?: SendQueryRequestOptions): Promise<T | null> => {
    try {
      setIsLoading(true)
      const result = await func()
      setIsLoading(false)
      return result
    } catch (err) {
      setIsLoading(false)
      const displayErrors = !opts || opts.showErrors
      if (err.response && err.response.status === 401) {
        ;(window.location as any) = getCognitoUrl(CognitoConfig)
        return null
      }

      if (displayErrors) {
        await error('An error occurred while getting the data.')
      }
      return null
    }
  }

  return {
    isLoading,
    sendQueryRequest,
  }
}

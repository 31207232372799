import React from 'react'
import { Popover } from 'antd'

import Button from '../../Button'
import Icon from '../../Icon'
import * as Style from './style'

type TableAction = {
  icon: string
  title: string
  color?: string
  onClick?: React.MouseEventHandler
}

type IProps = {
  actions: {
    [key: string]: TableAction[]
  }
  onInteract?: React.MouseEventHandler
}

export const PopoverContent = ({ actions }: IProps) => {
  return (
    <div>
      {Object.keys(actions).map((actionStr: string) => {
        const actionItems: TableAction[] = actions[actionStr]
        return (
          <div key={actionStr} className={Style.actionGroup}>
            <p className={Style.category}>{actionStr}</p>
            {actionItems.map((item: TableAction) => (
              <div
                key={item.title}
                className={Style.menuItem}
                onClick={item.onClick}
                style={{
                  color: item.color,
                }}
              >
                <span className={Style.menuIcon}>
                  {item.icon.includes('.svg') ? (
                    <Icon iconName={item.icon} height={12} width={12} />
                  ) : (
                    <i className={item.icon} />
                  )}
                </span>
                <span className={Style.menuTitle}>{item.title}</span>
              </div>
            ))}
          </div>
        )
      })}
    </div>
  )
}

const Component = ({ actions, onInteract }: IProps) => {
  return (
    <Popover
      className={Style.popover}
      placement="bottomLeft"
      trigger="click"
      content={<PopoverContent actions={actions} />}
      defaultVisible={false}
    >
      <Button
        className={Style.actionBtn}
        label=""
        type={'link'}
        onClick={(e: any) => {
          if (onInteract) onInteract(e)
        }}
        icon={<Icon iconName="table-action.svg" width={3} height={18} />}
      />
    </Popover>
  )
}

export default Component

import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Components from '@cloudmeet/web-components'

import * as Yup from 'yup'
import PageCard from '../common/components/PageCard'
import * as Routes from '../appContainer/routes'
import * as Style from './style'
import { Formik, FormikValues } from 'formik'
import { RoomTypeInfo, RoomTypesList } from './schemas'
import useCommandRequest from '../common/hooks/useCommandRequest'
import { getRoomRecordingDescription } from '@cloudmeet/web-core/rooms/helpers'
import { replaceId } from '@cloudmeet/web-core/common/utils/urlUtils'
import { toFullDateFromString } from '@cloudmeet/web-core/common/utils/dateUtils'
import { deleteRoom, getRooms, RoomDto } from '@cloudmeet/web-core/rooms/api'
import VideoConfig from '@cloudmeet/web-core/common/config/video'
import useQueryRequestV2 from '../common/hooks/useQueryRequestV2'
import { EmptyPagedList, PagedList } from '@cloudmeet/web-core/common/models/api'
import { success } from '@cloudmeet/web-components/src/utils/notifications'

/** ------------------------------------ **
 Global declarations
 ** ------------------------------------ **/
const { Button, Table, TableActions, Input, DeleteModal, Tag } = Components.UI
const { Colors } = Components.Styles

type Props = {
  type: any
}

export default ({ type }: Props) => {
  /** ------------------------------------ **
   Hooks declaration
   ** ------------------------------------ **/
  const history = useHistory()

  /** ------------------------------------ **
   Local states
   ** ------------------------------------ **/
  const roomTypeInfo: RoomTypeInfo | undefined = RoomTypesList.find((x) => x.id === type)
  const [filter, setFilter] = useState<any>({
    pageNumber: 1,
    pageSize: 20,
    orderColumn: 'createdAt',
    orderDescending: true,
    q: '',
    type: type,
  })
  const { isLoading: isDeleting, sendCommandRequest } = useCommandRequest()
  const [itemToDelete, setItemToDelete] = useState<any>(null)

  const [roomsPageList, isRoomsLoading, sendGetRoomsRequest] = useQueryRequestV2(EmptyPagedList())

  const columns: any[] = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Recording?',
      dataIndex: 'recordingOption',
      key: 'recordingOption',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: (_: any, record: any) => getRoomRecordingDescription(record.recordingOption),
    },
    {
      title: 'Private?',
      dataIndex: 'privateRoom',
      key: 'privateRoom',
      align: 'center',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: (_: any, record: any) => (record.privateRoom ? 'Yes' : 'No'),
    },
    {
      title: 'Participants',
      dataIndex: 'totalParticipants',
      key: 'totalParticipants',
      align: 'center',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: (_: any, record: any) => (
        <a onClick={() => history.push(replaceId(Routes.PARTICIPANTS, record.id))}>{record.totalParticipants}</a>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'presentationStarted',
      key: 'presentationStarted',
      align: 'center',
      render: (_: any, record: any) => (
        <>
          {!record.presentationStarted && <Tag status={'default'} text={'Not Started'} />}
          {record.presentationStarted && !record.presentationEnded && <Tag status={'active'} text={'Started'} />}
          {record.presentationEnded && <Tag status={'inactive'} text={'Ended'} />}
        </>
      ),
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: (_: any, record: any) => toFullDateFromString(record.createdAt),
    },
    {
      width: '200px',
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      render: (_: any, record: any) => (
        <RoomTableActions record={record} roomTypeInfo={roomTypeInfo} onDeleteClick={() => setItemToDelete(record)} />
      ),
    },
  ]

  const columnToSort: any = columns.find((c) => c.dataIndex === filter.orderColumn)
  columnToSort.sortOrder = filter.orderDescending ? 'descend' : 'ascend'

  const updateRooms = async () => {
    await sendGetRoomsRequest(() => getRooms(filter))
  }

  /** ------------------------------------ **
   Effects
   ** ------------------------------------ **/

  useEffect(() => {
    ;(async () => {
      await updateRooms()
    })()
  }, [filter])

  /** ------------------------------------ **
   Event Handlers
   ** ------------------------------------ **/
  const onTableChange = (pagination: any, filters: any, sorter: any) => {
    setFilter({
      ...filter,
      pageNumber: pagination.current,
      pageSize: pagination.pageSize,
      orderColumn: sorter.field || 'createdAt',
      orderDescending: sorter.order !== 'ascend',
    })
  }

  const onRoomDelete = async (roomId: string) => {
    const result = await sendCommandRequest(() => deleteRoom(roomId))
    if (!result.hasErrors) {
      setItemToDelete(null)
      await updateRooms()
    }
  }

  const actions = [
    <div className={Style.filterContainer}>
      <Formik
        enableReinitialize
        initialValues={filter}
        validationSchema={Yup.object()}
        onSubmit={(values: FormikValues) => {
          setFilter({
            ...filter,
            pageNumber: 1,
            q: values.q,
          })
        }}
      >
        {(form) => (
          <>
            <Input
              name={'q'}
              {...form}
              placeholder={'Search'}
              handleSubmit={() => {
                form.handleSubmit()
              }}
            />
          </>
        )}
      </Formik>
      <Button
        label={`Add ${roomTypeInfo?.single}`}
        type="normal"
        icon={<i className="ri-add-line mr-4"></i>}
        onClick={() => {
          history.push(`${Routes.ROOMS_ADD}?type=${type}`)
        }}
      />
    </div>,
  ]

  /** ------------------------------------ **
   Main Component
   ** ------------------------------------ **/
  return (
    <>
      <PageCard title={roomTypeInfo?.plural} actions={actions}>
        <Table
          loading={isRoomsLoading}
          pagination={{
            total: roomsPageList?.totalItemCount,
            pageSize: roomsPageList?.pageSize,
            current: roomsPageList?.pageNumber,
            showSizeChanger: true,
            pageSizeOptions: ['5', '10', '20', '30', '40', '50'],
          }}
          dataSource={roomsPageList?.items}
          columns={columns}
          onChange={onTableChange}
        />
        <DeleteModal
          visible={itemToDelete != null}
          name={itemToDelete?.name}
          onCancelClick={() => {
            setItemToDelete(null)
          }}
          onDeleteClick={() => onRoomDelete(itemToDelete.id)}
          deleteButtonLoading={isDeleting}
        />
      </PageCard>
    </>
  )
}

type ActionsType = {
  Actions?: any[]
  Presentation?: any[]
  Participants?: any[]
}

const RoomTableActions = ({ record, onDeleteClick, roomTypeInfo }: any) => {
  const history = useHistory()

  const actions: ActionsType = {}

  const hasActions = !record.presentationStarted || record.totalParticipants === 0 || record.presentationEnded

  if (hasActions) {
    actions.Actions = []
  }

  if (!record.presentationStarted) {
    actions.Actions?.push({
      icon: 'action-edit-gray.svg',
      title: 'Edit',
      onClick: () => {
        history.push(`${replaceId(Routes.ROOMS_MANAGE, record.id)}?type=${roomTypeInfo.id}`)
      },
    })
  }

  if (record.presentationEnded) {
    actions.Actions?.push({
      icon: 'bar-chart-line.svg',
      title: 'View usage',
      onClick: () => {
        history.push(`${replaceId(Routes.ROOM_USAGE, record.id)}`)
      },
    })
  }

  if (record.totalParticipants === 0) {
    actions.Actions?.push({
      icon: 'action-remove-orange.svg',
      color: Colors.SunsetOrange,
      title: 'Remove Room',
      onClick: () => onDeleteClick(record),
    })
  }

  if (!record.privateRoom) {
    actions.Presentation = [
      {
        icon: 'ri-file-copy-line',
        title: 'Copy invitation link',
        onClick: () => {
          return navigator.clipboard
            .writeText(`${VideoConfig.videoURL}?roomId=${record.id}&roomKey=${record.publicAccessKey}`)
            .then(() => {
              success('Copied')
            })
        },
      },
    ]
  }

  actions.Participants = [
    {
      icon: 'ri-user-line',
      title: 'All participants',
      onClick: () => {
        history.push(replaceId(Routes.PARTICIPANTS, record.id))
      },
    },
    {
      icon: 'ri-user-add-line',
      title: 'Add participant',
      onClick: () => {
        history.push(replaceId(Routes.PARTICIPANTS_MANAGE, record.id))
      },
    },
  ]

  return <TableActions actions={actions} />
}

import React, { useEffect, useState } from 'react'
import { Redirect, Route, Switch, useHistory } from 'react-router-dom'
import Components from '@cloudmeet/web-components'
import getUserMenuItems, { MenuItem } from './menu'
import * as Style from './style'
import useQueryRequest from '../common/hooks/useQueryRequest'
import { getLoginUserDetails, LoginUserDetailsResponse } from '@cloudmeet/web-core/users/api'
import CognitoConfig, { getCognitoUrl } from '@cloudmeet/web-core/common/config/cognito'
import * as Routes from './routes'
import NotFound from '../common/components/NotFound'
import Login from '../auth/Login'
import Dashboard from '../dashboard'
import Tenants from '../tenants/List'
import EditTenant from '../tenants/Edit'
import ListKeynotes from '../keynotes'
import ListBooths from '../booth'
import ListPanelGroups from '../panelGroup'
import ListRoundTables from '../roundTable'
import ManageRoom from '../rooms/ManageRoom'
import Participants from '../participants/Lists'
import ManageParticipant from '../participants/Manage'
import ListWebhooks from '../webhooks'
import ManageWebhook from '../webhooks/manage'
import RoomUsage from '../rooms/RoomUsage'

const { Header, Navigation } = Components.UI
const { SideMenu } = Navigation

type IProps = {}

const AppContainer: React.FC<IProps> = (props: IProps) => {
  /** ------------------------------------ **
   Hooks declaration
   ** ------------------------------------ **/
  const history = useHistory()
  const { sendQueryRequest } = useQueryRequest()

  /** ------------------------------------ **
   Entity States
   ** ------------------------------------ **/
  const [userDetails, setUserDetails] = useState<LoginUserDetailsResponse | null>(null)
  const [userMenuItems, setUserMenuItems] = useState<MenuItem[]>([])
  const [selectedMenuItem, setSelectedMenuItem] = useState<string | undefined>()

  /** ------------------------------------ **
   Effects
   ** ------------------------------------ **/
  useEffect(() => {
    ;(async () => {
      const result = await sendQueryRequest<LoginUserDetailsResponse>(() => getLoginUserDetails())
      if (result) {
        setUserDetails(result)

        const userMenuItemsResult = getUserMenuItems(result.permissions ?? [])
        setUserMenuItems(userMenuItemsResult)

        setSelectedMenuItem(getMenuItemFromPath(userMenuItemsResult, history.location.pathname)?.id)
      }
    })()
  }, [])

  /** ------------------------------------ **
   Event Handlers
   ** ------------------------------------ **/

  const onLogout = () => {
    localStorage.setItem('token', '')
    window.location.href = getCognitoUrl(CognitoConfig)
  }

  return (
    <div>
      <Header
        userName={userDetails ? `${userDetails.firstName} ${userDetails.lastName}` : '- / -'}
        onUserIconClick={() => {}}
        onUserArrowClick={() => {}}
        onLogoClick={() => {}}
        onNotificationsClick={() => {}}
        onUserLogoutClick={onLogout}
      />
      <div className={Style.bodyContainer}>
        <SideMenu
          items={userMenuItems}
          onItemClick={(e) => {
            const candidatePath = getMenuItemFromPath(userMenuItems, history.location.pathname)
            if (candidatePath) {
              setSelectedMenuItem(candidatePath.id)
            }
          }}
          active={selectedMenuItem}
        />
        <div className={Style.body}>
          <Switch>
            <Route key={Routes.NOT_FOUND} path={Routes.NOT_FOUND} component={NotFound} />
            <Route key={Routes.LOGIN} path={Routes.LOGIN} component={Login} />
            <Route key={Routes.DASHBOARD} path={Routes.DASHBOARD} component={Dashboard} exact />
            <Route key={Routes.TENANTS} path={Routes.TENANTS} component={Tenants} exact />
            <Route key={Routes.TENANT_EDIT} path={Routes.TENANT_EDIT} component={EditTenant} exact />
            <Route key={Routes.TENANT_ADD} path={Routes.TENANT_ADD} component={EditTenant} exact />
            <Route key={Routes.KEYNOTES} path={Routes.KEYNOTES} component={ListKeynotes} exact />
            <Route key={Routes.BOOTH} path={Routes.BOOTH} component={ListBooths} exact />
            <Route key={Routes.PANEL_GROUP} path={Routes.PANEL_GROUP} component={ListPanelGroups} exact />
            <Route key={Routes.ROUND_TABLE} path={Routes.ROUND_TABLE} component={ListRoundTables} exact />
            <Route key={Routes.ROOMS_MANAGE} path={Routes.ROOMS_MANAGE} component={ManageRoom} exact />
            <Route key={Routes.ROOM_USAGE} path={Routes.ROOM_USAGE} component={RoomUsage} exact />
            <Route key={Routes.ROOMS_ADD} path={Routes.ROOMS_ADD} component={ManageRoom} exact />
            <Route key={Routes.PARTICIPANTS} path={Routes.PARTICIPANTS} component={Participants} exact />
            <Route
              key={Routes.PARTICIPANTS_MANAGE}
              path={Routes.PARTICIPANTS_MANAGE}
              component={ManageParticipant}
              exact
            />
            <Route key={Routes.WEBHOOKS} path={Routes.WEBHOOKS} component={ListWebhooks} exact />
            <Route key={Routes.WEBHOOKS_EDIT} path={Routes.WEBHOOKS_EDIT} component={ManageWebhook} exact />
            <Route key={Routes.WEBHOOKS_ADD} path={Routes.WEBHOOKS_ADD} component={ManageWebhook} exact />
            <Redirect to={Routes.NOT_FOUND} />
          </Switch>
        </div>
      </div>
    </div>
  )
}

export default AppContainer

const getMenuItemFromPath = (menuItems: MenuItem[], path: string) => menuItems.find((x) => x.path === path)

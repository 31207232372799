import { css } from '@emotion/css'
import Styles from '../../styles'

const { Colors } = Styles

export const tag = css`
  border-radius: 4px !important;
  height: 22px !important;
  border: none !important;
`

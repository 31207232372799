export type Theme = {
  primaryColor: string
  backgroundColor: string
}

const defaultTheme: Theme = {
  primaryColor: '#0072D6',
  backgroundColor: '#1A202C',
}

export const storeTheme = (theme: Theme) => {
  localStorage.setItem('theme', JSON.stringify(theme))
}

export const getTheme = (): Theme => {
  try {
    const result = localStorage.getItem('theme')
    return result ? JSON.parse(result) : defaultTheme
  } catch (e: any) {
    return defaultTheme
  }
}

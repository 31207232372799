import React from 'react'
import { Statistic } from 'antd'

import * as Style from './style'

export interface IProps {
  title: string
  value: number | string
  precision?: number
  valueStyle?: React.CSSProperties
  prefix?: React.ReactNode
  suffix?: React.ReactNode
}

const Component: React.FunctionComponent<IProps> = (props: IProps) => {
  return (
    <div className={Style.wrap}>
      <Statistic
        title={props.title}
        value={props.value}
        precision={props.precision}
        valueStyle={props.valueStyle}
        prefix={props.prefix}
        suffix={props.suffix}
      />
    </div>
  )
}

export default Component

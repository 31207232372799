import React, { useEffect } from 'react'

import { NOT_FOUND } from '../appContainer/routes'
import { useHistory } from 'react-router-dom'
import useQueryRequest from '../common/hooks/useQueryRequest'
import { getLoginUserDetails, LoginUserDetailsResponse } from '@cloudmeet/web-core/users/api'
import getUserMenuItems from '../appContainer/menu'

export default () => {
  const history = useHistory()
  const { sendQueryRequest } = useQueryRequest()

  useEffect(() => {
    ;(async () => {
      const result = await sendQueryRequest<LoginUserDetailsResponse>(() => getLoginUserDetails())
      if (result) {
        const userMenuItemsResult = getUserMenuItems(result.permissions ?? [])

        if (userMenuItemsResult.length > 0) return history.replace(userMenuItemsResult[0].path)
        else history.replace(NOT_FOUND)
      }
    })()
  }, [])

  return null
}

import { Permission } from '@cloudmeet/web-core/tenants/models'

export type MenuItem = {
  id: string
  title: string
  icon: string
  path: string
  permissions: Permission[]
}

const menu: MenuItem[] = [
  {
    id: 'clients',
    title: 'Clients',
    icon: 'ri-user-line',
    path: '/clients',
    permissions: [Permission.All],
  },
  {
    id: 'keynotes',
    title: 'Keynotes',
    icon: 'ri-slideshow-line',
    path: '/keynotes',
    permissions: [],
  },
  {
    id: 'booth',
    title: 'Booth',
    icon: 'ri-group-line',
    path: '/booth',
    permissions: [],
  },
  {
    id: 'panelGroup',
    title: 'Panel Group',
    icon: 'ri-projector-fill',
    path: '/panel-group',
    permissions: [],
  },
  {
    id: 'roundTable',
    title: 'Round Table',
    icon: 'ri-checkbox-blank-circle-line',
    path: '/round-table',
    permissions: [],
  },
  {
    id: 'webhooks',
    title: 'Webhooks',
    icon: 'ri-links-line',
    path: '/webhooks',
    permissions: [],
  },
]

const getUserMenuItems = (userPermissions: Permission[]): MenuItem[] => {
  let userMenuItems: MenuItem[] = []

  menu.forEach((x: MenuItem) => {
    if (x.permissions.length > 0) {
      const hasPermission = x.permissions.some((p) => userPermissions.includes(p))
      if (hasPermission) {
        userMenuItems.push(x)
      }
    } else {
      userMenuItems.push(x)
    }
  })

  return userMenuItems
}

export default getUserMenuItems

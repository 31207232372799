import React from 'react'
import * as Style from './style'
import { Popover as AntPopover } from 'antd'
import { RenderFunction } from 'antd/lib/_util/getRenderPropValue'
import { TooltipPlacement } from 'antd/lib/tooltip'

type PopoverOptions = {
  title?: React.ReactNode | RenderFunction
  content?: React.ReactNode | RenderFunction
  children?: React.ReactNode
  trigger?: 'hover' | 'click' | 'focus'
  placement?: TooltipPlacement
}
const Popover = (props: PopoverOptions) => (
  <AntPopover
    content={props.content}
    placement={props.placement}
    title={props.title}
    trigger={props.trigger ?? 'click'}
  >
    {props.children}
  </AntPopover>
)

export default Popover

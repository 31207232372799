import React, { useState } from 'react'
import { Modal } from 'antd'
import { ModalProps } from 'antd/lib/modal'

import Icon from '../Icon'
import * as Style from './style'

type Props = {
  children?: React.ReactNode
  closeIcon?: boolean
  footer?: React.ReactNode
}

export default (props: JSX.IntrinsicAttributes & ModalProps & Props) => {
  return (
    <Modal
      {...props}
      destroyOnClose={true}
      className={Style.modal}
      maskStyle={{ backgroundColor: 'rgba(242, 242, 242, 0.6)' }}
      footer={props.footer ?? null}
      closeIcon={props.closeIcon ? <Icon iconName="close.svg" width={12} onClick={props.onCancel} /> : null}
    />
  )
}

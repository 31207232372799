import React, { MouseEventHandler } from 'react'
import Modal from '../Modal/index'
import Icon from '../Icon/index'
import * as Style from './style'
import Button from '../Button/index'
import Title from '../Title/index'

export type DeleteModalProps = {
  name: string
  onCancelClick: MouseEventHandler<HTMLElement>
  onDeleteClick: MouseEventHandler<HTMLElement>
  visible: boolean
  deleteButtonLoading?: boolean
}

const Component = ({ name, visible, onCancelClick, onDeleteClick, deleteButtonLoading }: DeleteModalProps) => (
  <Modal visible={visible} closeIcon={false}>
    <div className={Style.deactivateModalHeader}>
      <Icon iconName="info-orange.svg" />
      <Title type="modal-header" value={`Delete ${name}?`} />
    </div>
    <p className={Style.modalContentText}>Are sure you want to delete {name}?</p>
    <div className={Style.modalActions}>
      <div className={Style.modalActionsRightContainer}>
        <Button label="Cancel" type="secondary" onClick={onCancelClick} />
        <Button
          loading={deleteButtonLoading}
          disabled={deleteButtonLoading}
          label="Delete"
          type="warning"
          onClick={onDeleteClick}
        />
      </div>
    </div>
  </Modal>
)

export default Component

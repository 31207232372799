import React, { MouseEventHandler } from 'react'

import Icon from '../Icon'
import Button from '../Button'
import * as Style from './style'
import LoggedInUserHeader from '../User/LoggedInUserHeader'

type IProps = {
  userName: string
  onUserIconClick: MouseEventHandler<HTMLElement>
  onUserArrowClick: MouseEventHandler<HTMLElement>
  onLogoClick: MouseEventHandler<HTMLElement>
  onNotificationsClick: MouseEventHandler<HTMLElement>
  onUserLogoutClick: MouseEventHandler<HTMLElement>
}

const Component = ({
  onLogoClick,
  onNotificationsClick,
  userName,
  onUserIconClick,
  onUserArrowClick,
  onUserLogoutClick,
}: IProps) => {
  return (
    <div className={Style.header}>
      <div className={Style.logo}>
        <Icon iconName="logo.svg" width={120} onClick={onLogoClick} />
      </div>
      <div className={Style.userActions}>
        <Button
          className={Style.notifications}
          type="light"
          isCircle={true}
          icon={<Icon iconName="notifications-gray.svg" />}
          label={''}
          onClick={onNotificationsClick}
        />
        <LoggedInUserHeader
          userName={userName}
          onUserIconClick={onUserIconClick}
          onUserArrowClick={onUserArrowClick}
          onLogoutClick={onUserLogoutClick}
        />
      </div>
    </div>
  )
}

export default Component

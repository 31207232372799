import React from 'react'
import * as Style from './style'
import { Popconfirm } from 'antd'
import { RenderFunction } from 'antd/lib/_util/getRenderPropValue'
import { TooltipPlacement } from 'antd/lib/tooltip'

export type ComponentProps = {
  title: React.ReactNode | RenderFunction
  onConfirm?: (e?: React.MouseEvent<HTMLElement>) => void
  onCancel?: (e?: React.MouseEvent<HTMLElement>) => void
  children: React.ReactNode | RenderFunction
  placement?: TooltipPlacement
  okButtonClassName?: string
}

export const PopConfirmButtonStyles = {
  Default: Style.okButton,
  Red: Style.okRedButton,
}

const Component = (props: ComponentProps) => (
  <Popconfirm
    okButtonProps={{ className: props.okButtonClassName || Style.okButton }}
    className={Style.container}
    title={props.title}
    onConfirm={props.onConfirm}
    onCancel={props.onCancel}
    okText="Yes"
    cancelText="No"
  >
    {props.children}
  </Popconfirm>
)

export default Component

import React from 'react'
import { Card } from 'antd'

import * as Style from './style'

export interface IProps {
  title?: React.ReactNode
  extra?: React.ReactNode
  style?: React.CSSProperties
  children?: React.ReactNode
}

const Component: React.FunctionComponent<IProps> = (props: IProps) => {
  return (
    <Card className={Style.wrap} title={props.title} extra={props.extra} style={props.style}>
      {props.children}
    </Card>
  )
}

export default Component

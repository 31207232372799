import { getTheme } from '../utils/theme'

export const PrimaryColor: string = getTheme().primaryColor
export const BackgroundColor: string = getTheme().backgroundColor
export const DaybreakBlueLight: string = '#E6F7FF'
export const Cyan: string = '#13c2c2'
export const SunsetOrange: string = '#FA8C16'
export const Red: string = '#F5222D'

export const Gray2: string = '#F7F8FA'
export const Gray3: string = '#F0F2F5'
export const Gray8: string = '#535C68'
export const Gray6: string = '#B1B7C0'
export const Gray7: string = '#808996'
export const Gray9: string = '#2D3748'
export const Gray10: string = '#1A202C'

export const LightGray: string = '#D3D9E0'
export const DarkGray: string = '#535C68'
export const White: string = '#fff'
export const Green: string = '#52c41a'

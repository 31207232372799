import { useEffect, useState } from 'react'

const usePageWidth = (): number => {
  const [width, setWidth] = useState<number>(1281)

  function handleWindowSizeChange(): void {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    setWidth(window.innerWidth)
    window.addEventListener('resize', handleWindowSizeChange)
    return (): void => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  return width
}

export default usePageWidth

import * as Routes from '../appContainer/routes'
import { RoomType } from '@cloudmeet/web-core/rooms/models'

export type RoomTypeInfo = {
  id: number
  plural: string
  single: string
  url: string
}

export const RoomTypesList: RoomTypeInfo[] = [
  { id: RoomType.Keynote, plural: 'Keynotes', single: 'Keynote', url: Routes.KEYNOTES },
  { id: RoomType.Booth, plural: 'Booth', single: 'Booth', url: Routes.BOOTH },
  { id: RoomType.PanelGroup, plural: 'Panel Group', single: 'Panel Group', url: Routes.PANEL_GROUP },
  { id: RoomType.RoundTable, plural: 'Round Table', single: 'Round Table', url: Routes.ROUND_TABLE },
]

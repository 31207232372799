import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import CopyToClipboard from 'react-copy-to-clipboard'
import Components from '@cloudmeet/web-components'

import PageCard from '../common/components/PageCard'
import * as Routes from '../appContainer/routes'
import * as Style from './style'

import { Formik, FormikValues } from 'formik'
import * as Yup from 'yup'
import useQueryRequest from '../common/hooks/useQueryRequest'
import useCommandRequest from '../common/hooks/useCommandRequest'
import { getTenantKeys, getTenants, retireTenant } from '@cloudmeet/web-core/tenants/api'
import { toFullDateFromString } from '@cloudmeet/web-core/common/utils/dateUtils'
import { replaceId } from '@cloudmeet/web-core/common/utils/urlUtils'

/** ------------------------------------ **
 Global declarations
 ** ------------------------------------ **/
const { Button, Icon, Table, TableActions, Tag, Modal, Title, Detail, DeleteModal, Input } = Components.UI
const { Colors } = Components.Styles

export default () => {
  /** ------------------------------------ **
   Hooks declaration
   ** ------------------------------------ **/
  const history = useHistory()

  /** ------------------------------------ **
   Local States
   ** ------------------------------------ **/
  const [selectedTenant, setSelectedTenant]: [any, Function] = useState(null)
  const [tenantKeys, setTenantKeys]: [any, Function] = useState(null)
  const [filter, setFilter] = useState<any>({
    pageNumber: 1,
    pageSize: 20,
    orderColumn: 'createdAt',
    orderDescending: true,
    q: '',
  })
  const [tenantsPageList, setTenantsPageList] = useState({
    items: [],
    totalItemCount: 0,
    pageSize: 0,
    pageNumber: 1,
  })

  const { isLoading: isTenantsLoading, sendQueryRequest } = useQueryRequest()
  const { isLoading: isTenantKeysLoading, sendQueryRequest: sendGetTenantKeysRequest } = useQueryRequest()
  const { isLoading: isRetiring, sendCommandRequest } = useCommandRequest()

  const updateTenants = async () => {
    const response = await sendQueryRequest<any>(() => getTenants(filter))
    setTenantsPageList(response)
  }

  /** ------------------------------------ **
   Effects
   ** ------------------------------------ **/
  useEffect(() => {
    ;(async () => {
      await updateTenants()
    })()
  }, [filter])

  /** ------------------------------------ **
   Event Handlers
   ** ------------------------------------ **/
  const onEditTenantClick = (item: any) => {
    history.push(replaceId(Routes.TENANT_EDIT, item.id))
  }

  const onDeactivateTenantClick = (item: any) => {
    setSelectedTenant({
      action: 'deactivate',
      data: item,
    })
  }

  const onShowKeysClick = async (item: any) => {
    setSelectedTenant({
      action: 'showKeys',
      data: item,
    })

    const result = await sendGetTenantKeysRequest(() => getTenantKeys(item.id))
    setTenantKeys(result)
  }

  const onTableChange = (pagination: any, filters: any, sorter: any) => {
    setFilter({
      ...filter,
      pageNumber: pagination.current,
      pageSize: pagination.pageSize,
      orderColumn: sorter.field || 'createdAt',
      orderDescending: sorter.order !== 'ascend',
    })
  }

  const onTenantRetire = async () => {
    const result = await sendCommandRequest(() => retireTenant(selectedTenant.data.id))
    if (!result.hasErrors) {
      setSelectedTenant(null)
      await updateTenants()
    }
  }

  const columns: any = [
    {
      title: 'Company Name',
      dataIndex: 'companyName',
      key: 'companyName',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: (_: any, record: any) => {
        let status
        switch (record.status) {
          case 0:
            status = <Tag status={'active'} text={'Active'} />
            break
          case 1:
            status = <Tag status={'inactive'} text={'Inactive'} />
            break
        }
        return status
      },
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: (_: any, record: any) => toFullDateFromString(record.createdAt),
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      render: (_: any, record: any) => (
        <TableActions
          actions={{
            Actions: [
              {
                icon: 'action-edit-gray.svg',
                title: 'Edit Client',
                onClick: () => onEditTenantClick(record),
              },
              {
                icon: 'action-remove-orange.svg',
                color: Colors.SunsetOrange,
                title: 'Deactivate Client',
                onClick: () => onDeactivateTenantClick(record),
              },
            ],
            Keys: [
              {
                icon: 'action-lock-gray.svg',
                title: 'Show Keys',
                onClick: () => onShowKeysClick(record),
              },
            ],
          }}
        />
      ),
    },
  ]

  const columnToSort: any = columns.find((c: any) => c.dataIndex === filter.orderColumn)
  columnToSort.sortOrder = filter.orderDescending ? 'descend' : 'ascend'

  /** ------------------------------------ **
   Helper Components
   ** ------------------------------------ **/
  const actions = [
    <div className={Style.filterContainer}>
      <Formik
        enableReinitialize
        initialValues={filter}
        validationSchema={Yup.object()}
        onSubmit={(values: FormikValues) => {
          setFilter({
            ...filter,
            pageNumber: 1,
            q: values.q,
          })
        }}
      >
        {(form) => (
          <>
            <Input
              name={'q'}
              {...form}
              placeholder={'Search'}
              handleSubmit={() => {
                form.handleSubmit()
              }}
            />
          </>
        )}
      </Formik>
      <Button
        label="New Client"
        type="normal"
        icon={<Icon iconName="user-add-white.svg" />}
        onClick={() => {
          history.push(Routes.TENANT_ADD)
        }}
      />
    </div>,
  ]

  /** ------------------------------------ **
   Main Component
   ** ------------------------------------ **/
  return (
    <PageCard title={'Clients'} actions={actions}>
      <div className={Style.body}>
        {selectedTenant && (
          <>
            {tenantKeys && (
              <TenantKeysModal
                apiKey={tenantKeys.apiKey}
                visible={selectedTenant.action === 'showKeys'}
                companyName={selectedTenant.data.companyName}
                secretKey={tenantKeys.secretKey}
                onClosed={() => {
                  setSelectedTenant(null)
                }}
              />
            )}

            <DeleteModal
              visible={selectedTenant.action === 'deactivate'}
              name={selectedTenant.data.companyName}
              onCancelClick={() => {
                setSelectedTenant(null)
              }}
              onDeleteClick={onTenantRetire}
              deleteButtonLoading={isRetiring}
            />
          </>
        )}
        <Table
          loading={isTenantsLoading}
          pagination={{
            total: tenantsPageList.totalItemCount,
            pageSize: tenantsPageList.pageSize,
            current: tenantsPageList.pageNumber,
            showSizeChanger: true,
            pageSizeOptions: ['5', '10', '20', '30', '40', '50'],
          }}
          className={Style.table}
          dataSource={tenantsPageList.items}
          columns={columns}
          onChange={onTableChange}
        />
      </div>
    </PageCard>
  )
}

type TenantKeysModalProps = {
  companyName: string
  visible: boolean
  apiKey: string
  secretKey: string
  onClosed: () => void
}
const TenantKeysModal = ({ companyName, visible, secretKey, apiKey, onClosed }: TenantKeysModalProps) => (
  <Modal visible={visible}>
    <Title type="modal-header" value={`${companyName} Keys`} />
    <div className={Style.modalContent}>
      <div className={Style.clipboardDetail}>
        <Detail label="App ID" value={apiKey} type={'block'} />
        <div className={Style.clipboardIcon}>
          <CopyToClipboard text={apiKey}>
            <Icon iconName="copy-to-clipboard.svg" />
          </CopyToClipboard>
        </div>
      </div>
      <div className={Style.clipboardDetail}>
        <div className={Style.modalDetailLimiter}>
          <Detail label="Secret Key" value={secretKey} type={'block'} />
        </div>
        <div className={Style.clipboardIcon}>
          <CopyToClipboard text={secretKey}>
            <Icon iconName="copy-to-clipboard.svg" />
          </CopyToClipboard>
        </div>
      </div>
    </div>
    <div className={Style.modalActionsRight}>
      <Button label="Close" type="secondary" onClick={() => onClosed()} />
    </div>
  </Modal>
)

import React, { MouseEventHandler } from 'react'
import * as Style from './style'
import { useHistory } from 'react-router-dom'

type MenuItem = {
  id: string
  title: string
  icon: string
  path: string
  roles?: string[]
}

type IProps = {
  items: MenuItem[]
  active?: string
  onItemClick: MouseEventHandler<HTMLElement>
}

const Component = ({ items, onItemClick, active }: IProps) => {
  const history = useHistory()
  return (
    <div className={Style.container}>
      <span className={Style.menuHeader}>Menu</span>
      {items.map((item: MenuItem) => {
        const isActive = active === item.id
        return (
          <div
            key={item.title}
            className={isActive ? Style.menuItemActive : Style.menuItem}
            onClick={(e: any) => {
              history.push(item.path)
              onItemClick(e)
            }}
          >
            <i className={item.icon} />
            <span className={isActive ? Style.menuItemTextActive : Style.menuItemText}>{item.title}</span>
          </div>
        )
      })}
    </div>
  )
}

export default Component

import React, { useEffect, useState } from 'react'
import { diffWithNowFormatted, diffWithNowReverseFormatted } from '@cloudmeet/web-core/common/utils/dateUtils'

type TimerType = 'Timer' | 'Countdown'
type TimerProps = {
  date: string
  format?: string
  type: TimerType
}

const Timer = React.memo((props: TimerProps) => {
  const [value, setValue] = useState('')
  const format = props.format ?? 'HH:mm:ss'

  useEffect(() => {
    const interval = setInterval(() => {
      const result =
        props.type === 'Timer'
          ? diffWithNowFormatted(props.date, format)
          : diffWithNowReverseFormatted(props.date, format)

      setValue(result)
    }, 1000)

    return () => clearInterval(interval)
  }, [])

  return <span>{value}</span>
})

export default Timer

import { RoomRecordingOption } from './models'

const roomRecordingOptionsWithDescriptions = new Map()
roomRecordingOptionsWithDescriptions.set(RoomRecordingOption.NoRecording, 'No recording')
roomRecordingOptionsWithDescriptions.set(RoomRecordingOption.RecordingIsDefinedByHost, 'Recording is defined by host')
roomRecordingOptionsWithDescriptions.set(RoomRecordingOption.RecordingIsRequired, 'Recording is required')

export const getRoomRecordingDescription = (roomRecordingOption: RoomRecordingOption) =>
  roomRecordingOptionsWithDescriptions.get(roomRecordingOption)

import callApi from '../common/api/callApi'

import { BroadcastRequest, CaptureBroadcastRequest } from './models'

export const STREAMER_PUBLISH_URL: string = 'https://director.millicast.com/api/director/publish'
export const STREAMER_SUBSCRIBE_URL: string = 'https://director.millicast.com/api/director/subscribe'
export const STREAMER_TURN_SERVER_URL: string = 'https://turn.millicast.com/webrtc/_turn'

const getICEServers = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await callApi('PUT', STREAMER_TURN_SERVER_URL)
      if (response.data.s !== 'ok') {
        //failed to get ice servers, resolve anyway to connect w/ out.
        resolve([])
        return
      }

      //call returns old format, this updates URL to URLS in credentials path.
      let list = response.data.v.iceServers
      const result: any = []
      list.forEach((iceServer: any) => {
        let v = iceServer.url
        if (!!v) {
          iceServer.urls = v
          delete iceServer.url
        }
        result.push(iceServer)
      })

      resolve(result)
    } catch {
      //failed to get ice servers, resolve anyway to connect w/ out.
      reject([])
    }
  })
}

export const startBroadcasting = async (request: BroadcastRequest) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      let iceServers = await getICEServers()
      let conf = {
        iceServers: iceServers,
        rtcpMuxPolicy: 'require',
        bundlePolicy: 'max-bundle',
      }

      // @ts-ignore
      let pc = new RTCPeerConnection(conf)

      request.stream.getTracks().forEach((track: MediaStreamTrack) => {
        pc.addTrack(track, request.stream)
      })

      let ws = new WebSocket(request.websocketUrl + '?token=' + request.jwt)

      ws.onopen = function () {
        console.log('ws::onopen')

        let offer = pc
          .createOffer({
            offerToReceiveAudio: true,
            offerToReceiveVideo: true,
          })
          .then((desc) => {
            console.log('createOffer Success!')
            pc.setLocalDescription(desc)
              .then(() => {
                let data = {
                  name: request.streamName,
                  sdp: desc.sdp,
                  codec: 'h264',
                }

                let payload = {
                  type: 'cmd',
                  transId: Math.random() * 10000,
                  name: 'publish',
                  data: data,
                }
                ws.send(JSON.stringify(payload))
              })
              .catch((e) => {
                reject(e)
                console.log('setLocalDescription failed: ', e)
              })
          })
          .catch((e) => {
            reject(e)
            console.log('createOffer Failed: ', e)
          })
      }

      ws.addEventListener('message', (evt) => {
        let msg = JSON.parse(evt.data)

        switch (msg.type) {
          case 'response':
            let data = msg.data
            let answer = new RTCSessionDescription({
              type: 'answer',
              sdp: data.sdp + 'a=x-google-flag:conference\r\n',
            })
            pc.setRemoteDescription(answer)
              .then((d) => {
                console.log('setRemoteDescription Success! ')
                console.log('YOU ARE BROADCASTING!')
                resolve(pc)
              })
              .catch((e) => {
                reject(e)
                console.log('setRemoteDescription failed: ', e)
              })
            break
        }
      })
    } catch (err) {
      reject(err)
    }
  })
}

// gets server path and auth token.
export const updateStreamerSubscribeAuth = (request: CaptureBroadcastRequest): any => {
  let jwt
  let url
  return new Promise((resolve, reject) => {
    let xhr = new XMLHttpRequest()
    xhr.onreadystatechange = function (evt) {
      if (xhr.readyState == 4) {
        let res = JSON.parse(xhr.responseText)
        console.log('res: ', res)
        console.log('status:', xhr.status, ' response: ', xhr.responseText)
        switch (xhr.status) {
          case 200:
            let d = res.data
            jwt = d.jwt
            url = d.urls[0]
            resolve({
              jwt: jwt,
              websocketUrl: url,
            })
            break
          default:
            reject(res)
        }
      }
    }
    xhr.open('POST', STREAMER_SUBSCRIBE_URL, true)
    xhr.setRequestHeader('Authorization', `Bearer ${request.subscriberToken}`)
    xhr.setRequestHeader('Content-Type', 'application/json')
    xhr.send(
      JSON.stringify({
        streamAccountId: request.accountId,
        streamName: request.streamName,
        // TODO: add api endpoint for secured subscription
        unauthorizedSubscribe: true,
      }),
    )
  })
}

export const listenBroadcasting = async (request: CaptureBroadcastRequest) => {
  let stream: MediaStream
  return new Promise<any>(async (resolve, reject) => {
    try {
      // Get auth creds
      const { websocketUrl, jwt } = await updateStreamerSubscribeAuth(request)
      let iceServers = await getICEServers()
      let conf = {
        iceServers: iceServers,
        rtcpMuxPolicy: 'require',
        bundlePolicy: 'max-bundle',
      }

      // @ts-ignore
      let pc = new RTCPeerConnection(conf)
      pc.ontrack = (event: RTCTrackEvent) => {
        stream = event.streams[0]
        // stream.addTrack(event.track)
      }

      let ws = new WebSocket(websocketUrl + '?token=' + jwt)

      ws.onopen = function () {
        console.log('ws::onopen')

        let offer = pc
          .createOffer({
            offerToReceiveAudio: true,
            offerToReceiveVideo: true,
          })
          .then((desc) => {
            console.log('createOffer Success!')
            pc.setLocalDescription(desc)
              .then(() => {
                //set required information for media server.
                let data = {
                  streamId: request.streamName, //Millicast accountId
                  sdp: desc.sdp,
                }
                //create payload
                let payload = {
                  type: 'cmd',
                  transId: 0,
                  name: 'view',
                  data: data,
                }
                ws.send(JSON.stringify(payload))
              })
              .catch((e) => {
                reject(e)
                console.log('setLocalDescription failed: ', e)
              })
          })
          .catch((e) => {
            reject(e)
            console.log('createOffer Failed: ', e)
          })
      }

      ws.addEventListener('message', (evt) => {
        let msg = JSON.parse(evt.data)

        switch (msg.type) {
          case 'response':
            let data = msg.data
            let remoteSdp = data.sdp

            /* handle older versions of Safari */
            if (remoteSdp && remoteSdp.indexOf('\na=extmap-allow-mixed') !== -1) {
              remoteSdp = remoteSdp
                .split('\n')
                .filter(function (line: string) {
                  return line.trim() !== 'a=extmap-allow-mixed'
                })
                .join('\n')
              console.log('trimed a=extmap-allow-mixed - sdp \n', remoteSdp)
            }
            let answer = new RTCSessionDescription({
              type: 'answer',
              sdp: remoteSdp,
            })
            pc.setRemoteDescription(answer)
              .then((d) => {
                console.log('setRemoteDescription Success! ')
                if (stream) {
                  resolve(stream)
                }
              })
              .catch((e) => {
                reject(e)
                console.log('setRemoteDescription failed: ', e)
              })
            break
        }
      })
    } catch (err) {
      reject(err)
    }
  })
}

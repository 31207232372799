import { css } from '@emotion/css'

export const container = css`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  margin: auto;
  margin-top: 50px;
  max-width: 384px;
  max-height: 500px;
`
export const formBody = css`
  margin: 30px 0;
  width: 100%;
`

export const hGroup = css`
  display: flex;
  justify-content: space-between;
  & .ant-form-item {
    margin-right: 15px;
  }
`

export const filterContainer = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  & .ant-row {
    margin-right: 16px;
    min-width: 300px;
    margin-bottom: 0;
  }
`

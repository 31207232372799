import React from 'react'
import { Col, Row } from 'antd'
declare type ColSpanType = number | string
declare type FlexType = number | 'none' | 'auto' | string
export interface ColSize {
  flex?: FlexType
  span?: ColSpanType
  order?: ColSpanType
  offset?: ColSpanType
  push?: ColSpanType
  pull?: ColSpanType
}

export interface IProps {
  flex?: FlexType
  span?: ColSpanType
  order?: ColSpanType
  offset?: ColSpanType
  push?: ColSpanType
  pull?: ColSpanType
  xs?: ColSpanType | ColSize
  sm?: ColSpanType | ColSize
  md?: ColSpanType | ColSize
  lg?: ColSpanType | ColSize
  xl?: ColSpanType | ColSize
  xxl?: ColSpanType | ColSize
  prefixCls?: string
  children?: React.ReactNode
}

const Component: React.FunctionComponent<IProps> = (props: IProps) => {
  return <Col {...props}>{props.children}</Col>
}

export default Component

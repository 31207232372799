import { useRef, useEffect } from 'react'

const useInterval = (callback: Function, delay: number) => {
  const cachedCallback: any = useRef()

  useEffect(() => {
    cachedCallback.current = callback
  }, [callback])

  useEffect(() => {
    const targetFn = () => {
      cachedCallback.current()
    }

    if (delay > 0) {
      const intervalId = setInterval(targetFn, delay)
      return () => {
        clearInterval(intervalId)
      }
    }
  }, [callback, delay])
}

export default useInterval

import React from 'react'

import RoomList from '../rooms/List'
import { RoomType } from '@cloudmeet/web-core/rooms/models'

export default () => {
  /** ------------------------------------ **
     Main Component
     ** ------------------------------------ **/
  return <RoomList type={RoomType.PanelGroup} />
}

import { css, cx } from '@emotion/css'

export const icon = cx(
  css`
    display: flex;
    //right: 12px !important;
    //font-size: 12px !important;
    //-webkit-transition: all 0.3s !important;
    //-webkit-transition: all 0.3s !important;
    //transition: all 0.3s !important;
    //-webkit-user-select: none !important;
    //-moz-user-select: none !important;
    //-ms-user-select: none !important;
    //-webkit-user-select: none !important;
    //-moz-user-select: none !important;
    //-ms-user-select: none !important;
    //user-select: none !important;
  `,
)

import { css } from '@emotion/css'
import Styles from '../../styles'

const { Colors } = Styles

export const table = css`
  & .ant-table-thead > tr > th {
    background: none !important;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
  }
  & .ant-table-cell {
    color: ${Colors.Gray8} !important;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
  }
  & .ant-table-thead {
    background: #f7f8fa !important;
  }
`

import { message } from 'antd'
import { ConfigOptions } from 'antd/lib/message'

export const setupNotifications = (config?: ConfigOptions) => {
  message.config({
    top: config?.top || 25,
    duration: config?.duration || 3,
    maxCount: config?.maxCount || 1,
  })
}

export const error = async (err: string, callback?: Function) => {
  await message.error(err)
  callback?.()
}

export const success = (txt: string) => {
  message.success(txt).then(() => {})
}

export const info = (txt: string) => {
  message.info(txt).then(() => {})
}

export const showErrors = (errors: string[]) => {
  message.error(errors.join(',')).then(() => {})
}

import callApi from '../common/api/apiRequest'
import { PagedList } from '../common/models/api'
const BASE_URL = 'tenants'

export type TenantDto = {
  name: string
  companyName: string
  email: string
  status: number
  createdAt: Date
}
export const getTenants = async (filter: any) => {
  return callApi<PagedList<TenantDto>>('get', `${BASE_URL}`, filter)
}

export type GetTenantKeysResponse = {
  apiKey: string
  secretKey: string
}
export const getTenantKeys = async (id: string) => {
  return callApi<GetTenantKeysResponse>('get', `${BASE_URL}/apiKeys/${id}`)
}

export const retireTenant = async (id: string) => {
  return callApi<void>('post', `${BASE_URL}/retire`, { id })
}

export type GetTenantsForSaveResponse = {
  tenantId?: string
  firstName: string
  lastName: string
  companyName: string
  email: string
}

export const getTenantsForSave = async (id?: string) => {
  return callApi<GetTenantsForSaveResponse>('get', `${BASE_URL}/forSave/${id}`)
}

export type SaveTenantRequest = {
  TenantId?: string
  firstName: string
  lastName: string
  password: string
  companyName: string
  email: string
  primaryColor: string
  backgroundColor: string
}
export const saveTenant = async (values: SaveTenantRequest) => {
  return callApi<string>('post', `${BASE_URL}`, values)
}

import * as Dom from './dom'
import * as Notifications from './notifications'
import * as Theme from './theme'
import * as Browser from './browser'

export default {
  Browser,
  Dom,
  Notifications,
  Theme,
}

import React from 'react'

import * as Style from './style'

const NotFound: React.FC = () => {
  return (
    <div className={Style.container}>
      <h1>Page Not Found</h1>
    </div>
  )
}

export default NotFound

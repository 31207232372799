import React from 'react'
import { Row } from 'antd'
import { Gutter } from 'antd/lib/grid/row'

export interface IProps {
  gutter?: Gutter | [Gutter, Gutter]
  children?: React.ReactNode
}

const Component: React.FunctionComponent<IProps> = (props: IProps) => {
  return <Row gutter={props.gutter}>{props.children}</Row>
}

export default Component

import React from 'react'
import * as Style from './style'

type IProps = {
  value: string
  type: 'title' | 'subtitle' | 'small-title' | 'modal-header'
}

const Component = ({ value, type }: IProps) => {
  let comp = null
  switch (type) {
    case 'title':
      comp = <h1 className={Style.title}>{value}</h1>
      break
    case 'subtitle':
      comp = <h2 className={Style.subtitle}>{value}</h2>
      break
    case 'small-title':
      comp = <h3 className={Style.smallTitle}>{value}</h3>
      break
    case 'modal-header':
      comp = <h3 className={Style.modalTitle}>{value}</h3>
      break
  }
  return comp
}

export default Component
